import { call, put, all, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";

import apiPaths from "shared/apiPaths";
import subscriptionActionTypes from "./subscriptionActionTypes";

export function* sendSubscription(action) {
    const {
        payload: { headers, data, onSuccess, onError }
    } = action;
    const apiConfig = {
        method: "POST",
        url: apiPaths.USER_SUBSCRIBE,
        headers: headers,
        data: data,
    };

    yield put({ type: subscriptionActionTypes.SEND_SUBSCRIPTION_PENDING });
    try {
        const response = yield call(axios, apiConfig);
        yield put({
            type: subscriptionActionTypes.SEND_SUBSCRIPTION_FULFILLED,
            payload: response.data
        });
        onSuccess && onSuccess();
    } catch (e) {
        const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
        yield put({
            type: subscriptionActionTypes.SEND_SUBSCRIPTION_REJECTED,
            payload: errorMessage
        });
        onError && onError(errorMessage);
    }
}

export function* sendSubscriptionSaga() {
    yield takeLatest(subscriptionActionTypes.SEND_SUBSCRIPTION, sendSubscription);
}

export default function* rootSaga() {
    yield all([
        fork(sendSubscriptionSaga),
    ]);
}
