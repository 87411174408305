import styled from "styled-components";

import { Button, Form, Checkbox } from "antd";
const FormItem = Form.Item;

export const PageContainer = styled.div`
  background: ${props => props.theme.primaryBackground};
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow-x: scroll;
`;
export const ActionContainer = styled.div`
  display: flex;
  max-width: 680px;
  width: 94%;
  margin: auto;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  overflow: hidden;
  @media (max-width: 575px) {
    flex-direction: column;
  }
`;
export const LeftSection = styled.div`
  width: 40%;
  min-height: 200px;
  background-image: ${props => `url("${props.image}")`};
  background-size: cover;
  background-position: center top;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px 35px 20px;
  @media (max-width: 575px) {
    width: 100%;
  }
`;
export const RightSection = styled.div`
  width: 60%;
  padding: 35px 35px 20px;
  @media (max-width: 575px) {
    width: 100%;
  }
`;
export const Logo = styled.img`
  width: 35px;
  height: 35px;
`;
export const Title = styled.h2`
  color: #ffffff;
  font-size: 28px;
`;
export const SpanText = styled.span`
  margin-right: 10px;
`;

export const SocialSection = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const SocialButtonsContainer = styled.div``;
export const SocialButton = styled(Button)`
  color: ${props => props.theme.button.primary.color} !important;
  background: ${props => props.theme.button.primary.background} !important;
  border-color: ${props => props.theme.button.primary.background} !important;
  margin-right: 10px !important;
`;
export const StyledForm = styled(Form)`
  color: inherit !important;
  .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`;
export const StyledFormItem = styled(FormItem)`
  color: inherit !important;
  .ant-form-item-control-wrapper {
    width: 100% !important;
  }
`;
export const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
export const ActionLink = styled.span`
  color: ${props => props.theme.secondaryColor} !important;
`;
export const StyledCheckbox = styled(Checkbox)`
    line-height: 20px !important;
`;
