import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { notification } from "antd";

import Button from "shared/components/Button";
import Spinner from "shared/components/Spinner";
import Modal from "shared/components/Modal";
import { getHeaders } from "shared/apiHelper";
import { getSeatMap } from "features/events/eventsActions";
import { optout } from "features/optout/optoutActions";
import MatchForm from "features/checkin/MatchForm";

const mapStateToProps = state => ({
  token: state.authReducer.token
});

const mapDispatchToProps = dispatch => ({
  getSeatMap: params => dispatch(getSeatMap(params)),
  optout: params => dispatch(optout(params))
});

class OptOut extends React.PureComponent {
  static propTypes = {
    getSeatMap: PropTypes.func.isRequired,
    refreshEvents: PropTypes.func.isRequired,
    optout: PropTypes.func.isRequired,
    token: PropTypes.object.isRequired,
    event: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      seatMap: null
    };
  }

  componentDidUpdate() {
    if (this.state.visible && !this.state.seatMap) {
      this.getSeatMap();
    }
  }

  setVisibility = visible => {
    this.setState({
      visible
    });
  };

  getSeatMap = () => {
    const onSuccess = data => {
      this.setState({
        seatMap: data
      });
    };
    const onError = () => {};
    this.props.getSeatMap({
      headers: getHeaders(this.props.token),
      params: {
        event_id: this.props.event.id,
        venue_id: process.env.REACT_APP_STADIA_CODE
      },
      onSuccess,
      onError
    });
  };

  handleSubmit = data => {
    const { event } = this.props;
    let errorMessage = "";
    const onSuccess = () => {
      notification.success({
        message: "Opt out request sent successfully"
      });
      this.props.refreshEvents();
      this.setVisibility(false);
    };

    const onError = error => {
      notification.error({
        message: error
      });
    };

    if (
      !event.user_data &&
      (!data.selectedSeat || !data.selectedSeat.complete)
    ) {
      errorMessage = "Please select stand, block, row and seat";
    }

    if (errorMessage) {
      notification.error({
        message: errorMessage
      });
    } else {
      const requestData = {
        venue_id: process.env.REACT_APP_STADIA_CODE,
        event_id: this.props.event.id,
        seat_id: event.user_data.seat_id || data.selectedSeat.id
      };
      if (data.ticket) {
        requestData.ticket_img = data.ticket;
      }
      if (data.identity) {
        requestData.user_identity_img = data.identity;
      }
      this.props.optout({
        headers: getHeaders(this.props.token),
        data: requestData,
        onSuccess,
        onError
      });
    }
  };

  renderModalContent = () => {
    const { seatMap } = this.state;
    const { event } = this.props;
    if (seatMap) {
      return (
        <MatchForm
          type="optout"
          event={event}
          handleSubmit={this.handleSubmit}
          seatMap={seatMap}
        />
      );
    }
    return <Spinner />;
  };

  render() {
    return (
      <React.Fragment>
        <Button
          id="optout_button"
          type="secondary"
          block
          size="large"
          onClick={() => {
            this.props.setParentVisibility(false);
            this.setVisibility(true);
          }}
        >
          Opt Out
        </Button>
        <Modal
          title="Opt Out"
          visible={this.state.visible}
          footer={null}
          centered={true}
          bodyStyle={{ minHeight: "250px" }}
          onCancel={() => {
            this.setVisibility(false);
          }}
        >
          {this.renderModalContent()}
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptOut);
