import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getAssets, postMyAsset } from 'features/assets/assetsActions';
import { getSeatMap } from 'features/events/eventsActions';
import { getHeaders } from 'shared/apiHelper';

import GalleryPage from './GalleryPage';

const mapStateToProps = state => ({
    assets: state.assetsReducer.assets,
    seatMap: state.eventsReducer.seatMap,
    token: state.authReducer.token,
    signinData: state.authReducer.signinData,
});

const mapDispatchToProps = dispatch => ({
    getAssets: params => dispatch(getAssets(params)),
    postMyAsset: params => dispatch(postMyAsset(params)),
    getSeatMap: params => dispatch(getSeatMap(params)),
});


class GalleryPageContainer extends React.PureComponent {
    static propTypes = {
        getAssets: PropTypes.func.isRequired,
        postMyAsset: PropTypes.func.isRequired,
        getSeatMap: PropTypes.func.isRequired,
        assets: PropTypes.object.isRequired,
        seatMap: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        token: PropTypes.object.isRequired,
        signinData: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
            assetsData: null,
            cards: null,
            date: moment(),
            websocketTime: 0
        };
        
    }

    componentDidMount() {
        this.dateInterval = setInterval(() => this.updateDate(), 1000);
        this.getSeatMap();
        this.getAsset();
    }

    componentDidUpdate() {
        const { location: { state: { event } }, history, signinData } = this.props;
        const { date } = this.state;
        if(event.end_time && date.isAfter(moment.unix(event.end_time)) && this.ws && this.state.websocketTime > 600) {
            this.closeWebSocket();
        }
        if (date.isAfter(moment.unix(event.start_time)) && !this.ws) {
            this.ws = new WebSocket("wss://etq4xk67qj.execute-api.eu-west-1.amazonaws.com/dev");
            this.ws.onopen = () => {
                this.websocketInterval = setInterval(() => this.updateWebSocketTime(this.state.websocketTime + 1), 1000);
                if (event.user_data) {
                    this.ws.send(JSON.stringify({ action: "update", data: { event_id: event.id, seat_id: event.user_data.seat_id, user_id: signinData.data.accessToken.payload.username } }));
                }
            };
            this.ws.onmessage = evt => {
                const eventData = JSON.parse(evt.data);
                message.success(eventData.message);
                if (eventData.message === "New Photo Available") {
                    this.getAsset();
                } else if (eventData.message === "Game Over") {
                    this.closeWebSocket();
                } else if (eventData.message === "user_conflict") {
                    history.push("/app/events");
                }
            };
        }
    }

    componentWillUnmount() {
        this.closeWebSocket();
        clearInterval(this.dateInterval);
    }

    getSeatMap = () => {
        const {
            location: {
                state: { event }
            },
            token,
            getSeatMap
        } = this.props;
    
        getSeatMap({
          headers: getHeaders(token),
          params: {
            event_id: event.id,
            venue_id: process.env.REACT_APP_STADIA_CODE,
          },
        });
      };

    updateDate = () => {
        this.setState({
            date: moment()
        });
    }

    updateWebSocketTime = (value) => {
        this.setState({
            websocketTime: value
        });
    }

    postMyAsset = (params) => {
        const { token, postMyAsset } = this.props;
        postMyAsset({
            ...params,
            headers: getHeaders(token)
        });
    }

    getAsset = () => {
        const { location: { state: { event } }, getAssets, token } = this.props;
        const payload = {
            data: {
                "event_id": event.id,
            },
            headers: getHeaders(token),
            onSuccess: this.updateCards,
        };
        getAssets(payload);
    }

    updateCards = (data) => {
        this.setState({
            assetsData: data,
            cards: data.cards
        });
    }

    closeWebSocket = () => {
        if (this.ws) {
            clearInterval(this.websocketInterval);
            this.ws.close();
        }
    }

    render() {
        const { location: { state: { event } }, seatMap } = this.props;
        const { cards, assetsData } = this.state;
        if (cards && seatMap.isFulfilled) {
            return (
                <GalleryPage
                    event={event}
                    cards={cards}
                    seatMap={seatMap}
                    assetsData={assetsData}
                    postMyAsset={this.postMyAsset}
                />
            );
        }
        return null;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GalleryPageContainer));
