import { call, put, all, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";

import apiPaths from "shared/apiPaths";
import inviteActionTypes from "./inviteActionTypes";
import authActionTypes from "features/auth/authActionTypes";
import { TOKEN_EXPIRED_MESSAGE } from "shared/appConstants";

export function* inviteFriend(action) {
    const {
        payload: { data, headers, onSuccess, onError }
    } = action;
    const apiConfig = {
        method: "POST",
        url: apiPaths.INVITE_FRIEND,
        data: data,
        headers: headers
    };

    yield put({ type: inviteActionTypes.INVITE_FRIEND_PENDING });
    try {
        const response = yield call(axios, apiConfig);
        yield put({
            type: inviteActionTypes.INVITE_FRIEND_FULFILLED,
            payload: response.data.data
        });
        yield put({
            type: authActionTypes.UPDATE_TOKEN,
            payload: response.data.access_token
        });
        onSuccess && onSuccess();
    } catch (e) {
        const statusCode = e && e.response && e.response.status;
        const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
        if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
            yield put({ type: authActionTypes.SIGNOUT });
        }
        yield put({
            type: inviteActionTypes.INVITE_FRIEND_REJECTED,
            payload: errorMessage
        });
        onError && onError(errorMessage);
    }
}

export function* inviteFriendSaga() {
    yield takeLatest(inviteActionTypes.INVITE_FRIEND, inviteFriend);
}

export default function* rootSaga() {
    yield all([fork(inviteFriendSaga)]);
}
