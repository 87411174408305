import inviteActionTypes from './inviteActionTypes';

const inviteFriend = (payload) => {
    return {
        type: inviteActionTypes.INVITE_FRIEND,
        payload,
    };
};

export {
    inviteFriend,
};
