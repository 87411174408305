import venueActionTypes from './venueActionTypes';

const initialState = {
    venue: {
        isPending: false,
        isFulfilled: false,
        isRejected: false,
        data: null,
    }
};

const venueReducer = (state = initialState, action) => {
    switch (action.type) {
    case venueActionTypes.GET_VENUE_PENDING:
        return Object.assign({}, state, {
            venue: {
                isPending: true,
                isFulfilled: false,
                isRejected: false,
                data: null,
            },
        });
    case venueActionTypes.GET_VENUE_FULFILLED:
        return Object.assign({}, state, {
            venue: {
                isPending: false,
                isFulfilled: true,
                isRejected: false,
                data: action.payload,
            },
        });
    case venueActionTypes.GET_VENUE_REJECTED:
        return Object.assign({}, state, {
            venue: {
                isPending: false,
                isFulfilled: false,
                isRejected: true,
                data: action.payload,
            },
        });
    default:
        return state;
    }
};

export default venueReducer;
