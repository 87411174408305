import { call, put, all, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";

import apiPaths from "shared/apiPaths";
import optoutActionTypes from "./optoutActionTypes";
import authActionTypes from "features/auth/authActionTypes";
import { TOKEN_EXPIRED_MESSAGE } from "shared/appConstants";

export function* optout(action) {
    const {
        payload: { headers, data, onSuccess, onError }
    } = action;
    const apiConfig = {
        method: "POST",
        url: apiPaths.OPTOUT,
        headers: headers,
        data: data,
    };

    yield put({ type: optoutActionTypes.OPTOUT_PENDING });
    try {
        const response = yield call(axios, apiConfig);
        yield put({
            type: optoutActionTypes.OPTOUT_FULFILLED,
            payload: response.data.data
        });
        yield put({
            type: authActionTypes.UPDATE_TOKEN,
            payload: response.data.access_token
        });
        onSuccess && onSuccess();
    } catch (e) {
        const statusCode = e && e.response && e.response.status;
        const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
        if (
            statusCode === 401 &&
      errorMessage === TOKEN_EXPIRED_MESSAGE
        ) {
            yield put({ type: authActionTypes.SIGNOUT });
        }
        yield put({
            type: optoutActionTypes.OPTOUT_REJECTED,
            payload: errorMessage
        });
        onError && onError(errorMessage);
    }
}

export function* optoutSaga() {
    yield takeLatest(optoutActionTypes.OPTOUT, optout);
}

export default function* rootSaga() {
    yield all([
        fork(optoutSaga),
    ]);
}
