import React, { useState } from "react";
import StripeCheckout from "react-stripe-checkout";
import map from "lodash/map";
import { notification } from "antd";

import Button from "shared/components/Button";
import { Select, Option } from "shared/components/Select";

import { PlanWrapper, PlanHeader, PlanTitle, PlanBody } from "./styles";

const Plan = ({ plan, config, subscribePlan }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handleSubscription = async (token, productPlan) => {
    const onSuccess = () => {
      notification.success({
        message: "Success"
      });
    };
    const onError = error => {
      notification.success({
        message: error
      });
    };

    subscribePlan({
      data: {
        token_id: token.id,
        venue_id: process.env.REACT_APP_STADIA_CODE,
        uniq_key: productPlan
      },
      onSuccess,
      onError
    });
  };

  const renderPayButton = () => {
    if (!selectedPlan) {
      return (
        <Button disabled={true} type="primary">
          Buy Now
        </Button>
      );
    }
    return (
      <StripeCheckout
        name="Snaptivity"
        description={plan.title}
        token={token => handleSubscription(token, selectedPlan)}
        billingAddress={false}
        zipCode={false}
        panelLabel="Subscribe"
        stripeKey="pk_test_fz6IdE9TJUpGwoed2WiQgJQi"
      >
        <Button type="primary">Buy Now</Button>
      </StripeCheckout>
    );
  };

  const handleSelectChange = value => {
    setSelectedPlan(value);
  };

  const renderOptions = () => {
    return map(plan.sub_plans, (subPlan, index) => {
      return (
        <Option key={index} value={subPlan.uniq_key}>
          {`${subPlan.title} (${subPlan.amount})`}
        </Option>
      );
    });
  };

  return (
    <PlanWrapper width={config.width}>
      <PlanHeader background={config.background} color={config.color}>
        <PlanTitle>{plan.title}</PlanTitle>
      </PlanHeader>
      <PlanBody>
        <Select
          placeholder="Please Select a plan"
          onChange={handleSelectChange}
        >
          {renderOptions()}
        </Select>
        {renderPayButton()}
      </PlanBody>
    </PlanWrapper>
  );
};

export default Plan;
