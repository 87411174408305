import React, { useState } from "react";
import styled from "styled-components";
import { MoreOutlined } from "@ant-design/icons";
import map from "lodash/map";

import Button from "shared/components/Button";
import Modal from "shared/components/Modal";

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
`;

const MoreActions = ({ children }) => {
    const [visible, setVisibility] = useState(false);
    return (
      <React.Fragment>
        <Button
          id="more_actions_button"
          type="secondary"
          border="none"
          icon={<MoreOutlined />}
          onClick={() => setVisibility(true)}
        />
        <Modal
          visible={visible}
          footer={null}
          closable={false}
          centered={true}
          bodyStyle={{padding: 0}}
          onCancel={() => {
            setVisibility(false);
          }}
          style={{ padding: 0 }}
        >
          <ActionsWrapper>
              {React.Children.map(children, (child, index) => React.cloneElement(child, {key: index, setParentVisibility: setVisibility}))}
          </ActionsWrapper>
        </Modal>
      </React.Fragment>
    );
};

export default MoreActions;
