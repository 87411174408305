import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AccountBookOutlined, LogoutOutlined } from "@ant-design/icons";
import { signout } from "features/auth/authActions";

const NavigationList = styled.ul`
  padding: 40px 0 0 0;
  list-style: none;
`;

const NavigationListItem = styled.li`
  padding: 10px 33px;
`;

const LinkButton = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
`;

const NavigationText = styled.span`
  display: ${props => props.collapsed ? "none" : ""};
  color: inherit;
`;

const NavigationWrapper = styled.div`
  color: ${props => props.theme.sidebar.primary.color};
  &:hover {
    color: ${props => props.theme.sidebar.secondary.color};
  }
`;

const propTypes = {
    collapsed: PropTypes.bool
};

const Nav = ({ collapsed }) => {
    const dispatch = useDispatch();
    return (
        <NavigationList>
            <NavigationListItem>
                <Link id="events_button" to="/app/events">
                    <NavigationWrapper>
                        <AccountBookOutlined style={{marginRight: '10px'}} type="account-book" />
                        <NavigationText collapsed={collapsed}>
                          Events
                        </NavigationText>
                    </NavigationWrapper>
                </Link>
            </NavigationListItem>
            <NavigationListItem>
                <LinkButton id="signout_button" onClick={() => dispatch(signout())}>
                    <NavigationWrapper>
                        <LogoutOutlined style={{marginRight: '10px'}} type="logout" />
                        <NavigationText collapsed={collapsed}>Log Out</NavigationText>
                    </NavigationWrapper>
                </LinkButton>
            </NavigationListItem>
        </NavigationList>
    );
};

Nav.propTypes = propTypes;

export default Nav;
