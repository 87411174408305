import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { notification } from "antd";
import { withRouter } from "react-router-dom";

import Button from "shared/components/Button";
import Modal from "shared/components/Modal";
import { getHeaders } from "shared/apiHelper";
import { uploadDocuments } from "features/events/eventsActions";
import UploadDocumentsForm from "features/checkin/UploadDocumentsForm";

const mapStateToProps = state => ({
  token: state.authReducer.token
});

const mapDispatchToProps = dispatch => ({
  uploadDocuments: params => dispatch(uploadDocuments(params))
});

const propTypes = {
  uploadDocuments: PropTypes.func.isRequired,
  token: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired
};

const UploadDocuments = ({ refreshEvents, event, uploadDocuments, token }) => {
  const [visible, setVisibility] = useState(false);

  const uploadDocumentsSuccess = () => {
    refreshEvents();
    this.setTicketModalVisibility(false);
  };

  const uploadDocumentsError = error => {
    this.setTicketModalVisibility(false);
    notification.error({
      message: error
    });
  };

  const handleUploadDocuments = data => {
    let errorMessage = "";
    if (!data.ticket) {
      errorMessage = "Please upload your ticket";
    } else if (!data.identity) {
      errorMessage = "Please upload your identity";
    }

    if (errorMessage) {
      notification.error({
        message: errorMessage
      });
    } else {
      uploadDocuments({
        headers: getHeaders(token),
        data: {
          venue_id: process.env.REACT_APP_STADIA_CODE,
          event_id: event.id,
          seat_id: event.user_data.seat_id,
          user_identity_img: data.identity,
          ticket_img: data.ticket
        },
        onSuccess: uploadDocumentsSuccess,
        onError: uploadDocumentsError
      });
    }
  };

  return (
    <React.Fragment>
      <Button
        id="upload_documents_button"
        type="secondary"
        onClick={() => {
          setVisibility(true);
        }}
      >
        Upload documents
      </Button>
      <Modal
        title="Please upload your documents"
        visible={visible}
        footer={null}
        centered={true}
        onCancel={() => {
          setVisibility(false);
        }}
      >
        <UploadDocumentsForm handleSubmit={handleUploadDocuments} />
      </Modal>
    </React.Fragment>
  );
};

UploadDocuments.propTypes = propTypes;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UploadDocuments)
);
