import React from "react";
import PropTypes from "prop-types";
import { Hub } from "aws-amplify";

import Spinner from "shared/components/Spinner";
import { PageContainer } from "../commonStyles";
const propTypes = {
    getCurrentAuthenticatedUser: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
};

class Social extends React.Component {
    componentDidMount() {
        const { history, getCurrentAuthenticatedUser } = this.props;
        const onSuccess = () => {
            history.push("/app/events");
        };
        Hub.listen("auth", ({ payload: { event } }) => {
            switch (event) {
            case "signIn":
                getCurrentAuthenticatedUser({
                    onSuccess
                });
                break;
            default:
                history.push("/signin");
                break;
            }
        });
    }

    render() {
        return (
            <PageContainer>
                <Spinner />
            </PageContainer>
        );
    }
}

Social.propTypes = propTypes;
export default Social;
