import styled from 'styled-components';
import { Button } from 'antd';

export const PageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh);
    background: #000000;
`;
export const ShareContainer = styled.div`
    display: flex;
    @media (max-width: 900px) {
        flex-direction: column;
    }
`;
export const LeftContainer = styled.div`
    @media (max-width: 900px) {
        max-width: 500px;
    }
`;
export const ShareImage = styled.img``;
export const RightContainer = styled.div`
    background: #ffffff;
    padding: 50px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1080px) {
        padding: 30px 50px;
    }
    @media (max-width: 900px) {
        padding: 30px 50px;
        max-width: 500px;
    }
    @media (max-width: 400px) {
        padding: 20px;
    }
`;
export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 900px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;
export const FooterContainer = styled.div``;
export const Logo = styled.img`
    width: 70px;
    text-align: center;
    margin: auto;
    @media (max-width: 1080px) {
        width: 50px;
    }
`;
export const LogoText = styled.h1`
    font-size: 18px;
    margin: 5px 0 10px;
    font-weight: bold;
    text-align: center;
    @media (max-width: 900px) {
        font-size: 16px;
    }
`;
export const LogoSubText = styled.span`
    text-align: center;
    padding: 0 35px;
    @media (max-width: 900px) {
        display: none;
    }
`;
export const Title = styled.h1`
    font-size: 16px;
    font-weight: bold;
    color: #383838;
    margin: 80px 0 15px;
    @media (max-width: 1080px) {
        margin: 30px 0 15px;
    }
    @media (max-width: 900px) {
        margin: 0;
        font-size: 14px;
    }
`;
export const Paragraph = styled.p`
    text-align: left;
    @media (max-width: 900px) {
        display: none;
    }
`;
export const FollowUsText = styled.div``;
export const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
export const BodyContainer = styled.div`
    text-align: center;
`;
export const StyledButton = styled(Button)`
    margin: 20px 0;
    align-items: center;
    @media (max-width: 900px) {
        margin: 0;
    }
`;

export const HorizontalRowContainer = styled.div`
    display: block;
    position: relative;
`;
export const HorizontalRow = styled.hr`
    margin: 20px 0;
    border-color: black;
`;
export const HorizontalRowText = styled.span`
    background: #ffffff;
    display: block;
    padding: 0 10px;
    position: absolute;
    font-size: 14px;
    top: -10px;
    left: 35%;
`;
export const ShareButtons = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const ImageButton = styled.img`
    height: 40px;
    width: 40px;
`;
