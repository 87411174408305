import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { notification } from "antd";
import { withRouter } from "react-router-dom";

import Button from "shared/components/Button";
import Spinner from "shared/components/Spinner";
import Modal from "shared/components/Modal";
import { getHeaders } from "shared/apiHelper";
import {
  checkInUser,
  uploadDocuments,
  getSeatMap
} from "features/events/eventsActions";
import { optout } from "features/optout/optoutActions";
import MatchForm from "features/checkin/MatchForm";
import UploadDocumentsForm from "features/checkin/UploadDocumentsForm";

const mapStateToProps = state => ({
  token: state.authReducer.token,
  checkIn: state.authReducer.checkIn,
  reCheckIn: state.authReducer.reCheckIn
});

const mapDispatchToProps = dispatch => ({
  getSeatMap: params => dispatch(getSeatMap(params)),
  checkInUser: params => dispatch(checkInUser(params)),
  uploadDocuments: params => dispatch(uploadDocuments(params)),
  optout: params => dispatch(optout(params))
});

class Checkin extends React.PureComponent {
  static propTypes = {
    getSeatMap: PropTypes.func.isRequired,
    refreshEvents: PropTypes.func.isRequired,
    checkInUser: PropTypes.func.isRequired,
    uploadDocuments: PropTypes.func.isRequired,
    optout: PropTypes.func.isRequired,
    token: PropTypes.object.isRequired,
    event: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      ticketModalVisible: false,
      seatMap: null,
      checkinData: null
    };
  }

  componentDidUpdate() {
    if (this.state.visible && !this.state.seatMap) {
      this.getSeatMap();
    }
  }

  setVisibility = visible => {
    this.setState({
      visible
    });
  };

  setTicketModalVisibility = visible => {
    this.setState({
      ticketModalVisible: visible
    });
  };

  getSeatMap = () => {
    const onSuccess = data => {
      this.setState({
        seatMap: data
      });
    };
    const onError = () => {};
    this.props.getSeatMap({
      headers: getHeaders(this.props.token),
      params: {
        event_id: this.props.event.id,
        venue_id: process.env.REACT_APP_STADIA_CODE
      },
      onSuccess,
      onError
    });
  };

  checkinSuccess = (response, data) => {
    const { event, history } = this.props;
    if (response.status === "upload-documents") {
      this.setVisibility(false);
      this.setState({
        checkinData: data
      });
      this.props.refreshEvents();
      this.setTicketModalVisibility(true);
    } else if (response.status === "check-in") {
      this.setVisibility(false);
      history.push({
        pathname: `/app/gallery/${event.id}`,
        state: {
          event: {
            ...event,
            user_data: {
              seat_id: data.selectedSeat.id
            }
          }
        }
      });
    } else {
      this.setVisibility(false);
    }
  };

  checkinError = error => {
    this.setVisibility(false);
    notification.error({
      message: error
    });
  };

  handleSubmit = data => {
    let errorMessage = "";
    if (!data.selectedSeat || !data.selectedSeat.complete) {
      errorMessage = "Please select stand, block, row and seat";
    } else if (!data.concent) {
      errorMessage = "Please agree with terms of use";
    }

    if (errorMessage) {
      notification.error({
        message: errorMessage
      });
    } else {
      this.props.checkInUser({
        headers: getHeaders(this.props.token),
        data: {
          venue_id: process.env.REACT_APP_STADIA_CODE,
          event_id: this.props.event.id,
          seat_id: data.selectedSeat.id,
          user_team: data.team,
          consent: data.concent
        },
        onSuccess: response => this.checkinSuccess(response, data),
        onError: this.checkinError
      });
    }
  };

  uploadDocumentsSuccess = () => {
    this.props.refreshEvents();
    this.setTicketModalVisibility(false);
  };

  uploadDocumentsError = error => {
    this.setTicketModalVisibility(false);
    notification.error({
      message: error
    });
  };

  handleUploadDocuments = data => {
    let errorMessage = "";
    if (!data.ticket) {
      errorMessage = "Please upload your ticket";
    } else if (!data.identity) {
      errorMessage = "Please upload your identity";
    }

    if (errorMessage) {
      notification.error({
        message: errorMessage
      });
    } else {
      this.props.uploadDocuments({
        headers: getHeaders(this.props.token),
        data: {
          venue_id: process.env.REACT_APP_STADIA_CODE,
          event_id: this.props.event.id,
          seat_id: this.state.checkinData.selectedSeat.id,
          user_identity_img: data.identity,
          ticket_img: data.ticket
        },
        onSuccess: this.uploadDocumentsSuccess,
        onError: this.uploadDocumentsError
      });
    }
  };

  renderModalContent = () => {
    const { seatMap } = this.state;
    const { event } = this.props;
    if (seatMap) {
      return (
        <MatchForm
          type="checkin"
          event={event}
          handleSubmit={this.handleSubmit}
          seatMap={seatMap}
        />
      );
    }
    return <Spinner />;
  };

  render() {
    const { visible, ticketModalVisible } = this.state;
    return (
      <React.Fragment>
        <Button
          id="checkin_button"
          type="primary"
          onClick={() => {
            this.setVisibility(true);
          }}
        >
          Checkin
        </Button>
        <Modal
          title="Checkin"
          visible={visible}
          footer={null}
          centered={true}
          bodyStyle={{ minHeight: "250px" }}
          onCancel={() => {
            this.setVisibility(false);
          }}
        >
          {this.renderModalContent()}
        </Modal>
        <Modal
          title="Please upload your documents"
          visible={ticketModalVisible}
          footer={null}
          centered={true}
          onCancel={() => this.setTicketModalVisibility(false)}
        >
          <UploadDocumentsForm handleSubmit={this.handleUploadDocuments} />
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Checkin)
);
