import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
    PlusOutlined,
  } from '@ant-design/icons';

const UploadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    text-align: center;
    background: ${props => props.theme.primaryBackground} !important;
    color: ${props => props.theme.primaryColor} !important;
    border: 1px dashed rgba(255, 255, 255, 0.1);
    border-radius: 4px;
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
`;

const propTypes = {
    action: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

const UploadImage = ({ title, action }) => {
    const [image, setImage] = useState(null);

    const handleChange = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = () => {
            const file = input.files[0];
            const reader = new FileReader();
            reader.onloadend = function() {
                action(reader.result);
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
            
        };
    };

    if (image) {
        return <UploadContainer onClick={handleChange}><Image src={image} alt="uploaded" /></UploadContainer>;
    }

    return (
        <UploadContainer onClick={handleChange}>
            <PlusOutlined />
            <div>Upload <br/> {title}</div>
        </UploadContainer>
    );
};

UploadImage.propTypes = propTypes;

export default UploadImage;