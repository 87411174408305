import { call, put, all, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";

import apiPaths from "shared/apiPaths";
import plansActionTypes from "./plansActionTypes";
import authActionTypes from "features/auth/authActionTypes";
import { TOKEN_EXPIRED_MESSAGE } from "shared/appConstants";

export function* getPlans(action) {
  const {
    payload: { params, headers, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "GET",
    url: apiPaths.PLANS,
    headers: headers,
    params: params
  };

  yield put({ type: plansActionTypes.GET_PLANS_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: plansActionTypes.GET_PLANS_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess();
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: plansActionTypes.GET_PLANS_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* getPlansSaga() {
  yield takeLatest(plansActionTypes.GET_PLANS, getPlans);
}

export function* subscribePlan(action) {
    const {
      payload: { data, headers, onSuccess, onError }
    } = action;
    const apiConfig = {
      method: "POST",
      url: apiPaths.SUBSCRIBE_PLAN,
      headers: headers,
      data: data
    };
  
    yield put({ type: plansActionTypes.SUBSCRIBE_PLAN_PENDING });
    try {
      const response = yield call(axios, apiConfig);
      yield put({
        type: plansActionTypes.SUBSCRIBE_PLAN_FULFILLED,
        payload: response.data.data
      });
      yield put({
        type: authActionTypes.UPDATE_TOKEN,
        payload: response.data.access_token
      });
      onSuccess && onSuccess();
    } catch (e) {
      const statusCode = e && e.response && e.response.status;
      const errorMessage =
        e &&
        e.response &&
        e.response.data &&
        e.response.data.error &&
        e.response.data.error.message;
      if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
        yield put({ type: authActionTypes.SIGNOUT });
      }
      yield put({
        type: plansActionTypes.SUBSCRIBE_PLAN_REJECTED,
        payload: errorMessage
      });
      onError && onError(errorMessage);
    }
  }
  
  export function* subscribePlanSaga() {
    yield takeLatest(plansActionTypes.SUBSCRIBE_PLAN, subscribePlan);
  }

export default function* rootSaga() {
  yield all([
    fork(getPlansSaga),
    fork(subscribePlanSaga),
  ]);
}
