import styled from "styled-components";
import { Tabs as T } from "antd";

export const Tabs = styled(T)`
    .ant-tabs-bar {
        border-color: ${props => props.theme.tabs ? props.theme.tabs.border : ''} !important;
    }

    .ant-tabs-tab {
        color: ${props => props.theme.tabs ? props.theme.tabs.color : ''} !important;
    }

    .ant-tabs-ink-bar {
        background: ${props => props.theme.tabs ? props.theme.tabs.color : ''} !important;
    }
`;
export const TabPane = styled(T.TabPane)``;
