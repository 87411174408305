import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getCurrentAuthenticatedUser } from 'features/auth/authActions';

import Social from './Social';

const mapStateToProps = state => ({
    signinData: state.authReducer.signinData,
});

const mapDispatchToProps = dispatch => ({
    getCurrentAuthenticatedUser: params => dispatch(getCurrentAuthenticatedUser(params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Social));
