import React, { useState } from "react";
import map from "lodash/map";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";

import Button from "shared/components/Button";
import Modal from "shared/components/Modal";
import { REPORT_REASONS } from "shared/appConstants";
import { report } from "features/report/reportActions";
import { getHeaders } from "shared/apiHelper";

const Report = ({ event, photo, setParentVisibility }) => {
  const dispatch = useDispatch();
  const { token } = useSelector(({ authReducer }) => authReducer);
  const [visible, setVisibility] = useState(false);

  const onSubmit = value => {
    const onSuccess = () => {
      notification.success({
        message: "Image reported successfully"
      });
      setVisibility(false);
    };

    const onError = error => {
      notification.error({
        message: error
      });
    };

    dispatch(
      report({
        data: {
          venue_id: process.env.REACT_APP_STADIA_CODE,
          event_id: event.id,
          seat_id: event.user_data.seat_id,
          url: photo,
          reason: value
        },
        headers: getHeaders(token),
        onSuccess,
        onError
      })
    );
  };

  console.log(event);

  return (
    <React.Fragment>
      <Button
        id="report_button"
        type="secondary"
        block
        size="large"
        onClick={() => {
          setParentVisibility(false);
          setVisibility(true);
        }}
      >
        Report
      </Button>
      <Modal
        visible={visible}
        closable={false}
        footer={null}
        centered={true}
        bodyStyle={{ padding: 0 }}
        onCancel={() => {
          setVisibility(false);
        }}
      >
        <React.Fragment>
          {map(REPORT_REASONS, (reason, index) => {
            return (
              <Button
                key={index}
                block
                size="large"
                onClick={() => onSubmit(reason.name)}
              >
                {reason.name}
              </Button>
            );
          })}
        </React.Fragment>
      </Modal>
    </React.Fragment>
  );
};

export default Report;
