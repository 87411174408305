import { call, put, all, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";

import apiPaths from "shared/apiPaths";
import venueActionTypes from "./venueActionTypes";

export function* getVenue(action) {
  const {
    payload: { headers, params, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "GET",
    url: apiPaths.VENUE,
    headers: headers,
    params: params
  };

  yield put({ type: venueActionTypes.GET_VENUE_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: venueActionTypes.GET_VENUE_FULFILLED,
      payload: response.data
    });
    onSuccess && onSuccess(response.data);
  } catch (e) {
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    yield put({
      type: venueActionTypes.GET_VENUE_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* getVenueSaga() {
  yield takeLatest(venueActionTypes.GET_VENUE, getVenue);
}

export default function* rootSaga() {
  yield all([fork(getVenueSaga)]);
}
