import { call, put, all, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";

import apiPaths from "shared/apiPaths";
import eventsActionTypes from "./eventsActionTypes";
import authActionTypes from "features/auth/authActionTypes";
import { TOKEN_EXPIRED_MESSAGE } from "shared/appConstants";

export function* getEvents(action) {
  const {
    payload: { params, headers, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "GET",
    url: apiPaths.EVENTS,
    headers: headers,
    params: params
  };

  yield put({ type: eventsActionTypes.GET_EVENTS_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: eventsActionTypes.GET_EVENTS_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess();
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: eventsActionTypes.GET_EVENTS_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* getEventsSaga() {
  yield takeLatest(eventsActionTypes.GET_EVENTS, getEvents);
}

export function* getSeatMap(action) {
  const {
    payload: { headers, params, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "GET",
    url: apiPaths.SEATMAP,
    headers: headers,
    params: params
  };

  yield put({ type: eventsActionTypes.GET_SEATMAP_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: eventsActionTypes.GET_SEATMAP_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess(response.data.data);
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: eventsActionTypes.GET_SEATMAP_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* getSeatMapSaga() {
  yield takeLatest(eventsActionTypes.GET_SEATMAP, getSeatMap);
}

export function* checkInUser(action) {
  const {
    payload: { data, headers, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "POST",
    url: apiPaths.CHECKIN,
    data: data,
    headers: headers
  };

  yield put({ type: eventsActionTypes.CHECKIN_USER_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: eventsActionTypes.CHECKIN_USER_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess(response.data.data);
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: eventsActionTypes.CHECKIN_USER_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* checkInUserSaga() {
  yield takeLatest(eventsActionTypes.CHECKIN_USER, checkInUser);
}

export function* reCheckInUser(action) {
  const {
    payload: { data, headers, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "POST",
    url: apiPaths.RE_CHECKIN,
    data: data,
    headers: headers
  };

  yield put({ type: eventsActionTypes.RE_CHECKIN_USER_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: eventsActionTypes.RE_CHECKIN_USER_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess();
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: eventsActionTypes.RE_CHECKIN_USER_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* reCheckInUserSaga() {
  yield takeLatest(eventsActionTypes.RE_CHECKIN_USER, reCheckInUser);
}

export function* uploadDocuments(action) {
  const {
    payload: { data, headers, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "POST",
    url: apiPaths.UPLOAD_DOCUMENTS,
    data: data,
    headers: headers
  };

  yield put({ type: eventsActionTypes.UPLOAD_DOCUMENTS_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: eventsActionTypes.UPLOAD_DOCUMENTS_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess(response.data.data);
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: eventsActionTypes.UPLOAD_DOCUMENTS_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* uploadDocumentsSaga() {
  yield takeLatest(eventsActionTypes.UPLOAD_DOCUMENTS, uploadDocuments);
}

export default function* rootSaga() {
  yield all([
    fork(getEventsSaga),
    fork(getSeatMapSaga),
    fork(checkInUserSaga),
    fork(reCheckInUserSaga),
    fork(uploadDocumentsSaga)
  ]);
}
