import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import logo from 'shared/assets/images/logo_large.png';
import facebookLogo from 'shared/assets/images/facebook_logo.png';
import twitterLogo from 'shared/assets/images/twitter_logo.png';
import instagramLogo from 'shared/assets/images/instagram_logo.png';
import webLogo from 'shared/assets/images/web_logo.png';

import {
    PageWrapper,
    ShareContainer,
    LeftContainer,
    ShareImage,
    RightContainer,
    HeaderContainer,
    BodyContainer,
    FooterContainer,
    LogoContainer,
    Logo,
    LogoText,
    LogoSubText,
    Title,
    Paragraph,
    StyledButton,
    HorizontalRowContainer,
    HorizontalRow,
    HorizontalRowText,
    ShareButtons,
    ImageButton
} from './styles';

const propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

const Share = ({ location, history }) => {
    const urlParams = new URLSearchParams(location.search);
    const photo = urlParams.get('photo');

    const redirectToSignIn = () => {
        history.push('/signin');
    };

    return (
        <PageWrapper>
            <Helmet>
                <meta property="og:image" content={photo}></meta>
                <meta name="twitter:image" content={photo}></meta>
                <meta name="twitter:card" content="summary_large_image"></meta>
            </Helmet>
            <ShareContainer>
                <LeftContainer>
                    <ShareImage src={photo} />
                </LeftContainer>
                <RightContainer>
                    <HeaderContainer>
                        <LogoContainer>
                            <Logo src={logo}></Logo>
                            <LogoText>Snaptivity</LogoText>
                            <LogoSubText>
                                On a mission to capture & share the world&apos;s
                                moments at live events
                            </LogoSubText>
                        </LogoContainer>
                        <Title>
                            You focus on the game. <br />
                            We&apos;ll focus on you.
                        </Title>
                    </HeaderContainer>
                    <BodyContainer>
                        <Paragraph>
                            The moments when you leap up & roar with the crowd
                            are priceless. But you can&apos;t live them to the
                            full with a phone in your hand.
                            <br />
                            <br />
                            We have got you covered.
                        </Paragraph>
                        <StyledButton id="get_your_moments_button" type="primary" onClick={redirectToSignIn}>
                            Get Your Moments
                        </StyledButton>
                    </BodyContainer>
                    <FooterContainer>
                        <HorizontalRowContainer>
                            <HorizontalRow />
                            <HorizontalRowText>Follow Us</HorizontalRowText>
                        </HorizontalRowContainer>
                        <ShareButtons>
                            <a id="facebook_follow_button" href="https://facebook.com/snaptivity">
                                <ImageButton src={facebookLogo} />
                            </a>
                            <a id="twitter_follow_button" href="https://twitter.com/snaptivity">
                                <ImageButton src={twitterLogo} />
                            </a>
                            <a id="instagram_follow_button" href="https://instagram.com/snaptivity">
                                <ImageButton src={instagramLogo} />
                            </a>
                            <a id="website_follow_button" href="https://fanapp.snaptivityapp.com">
                                <ImageButton src={webLogo} />
                            </a>
                        </ShareButtons>
                    </FooterContainer>
                </RightContainer>
            </ShareContainer>
        </PageWrapper>
    );
};

Share.propTypes = propTypes;

export default withRouter(Share);
