import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import eventsReducer from "features/events/eventsReducer";
import assetsReducer from "features/assets/assetsReducer";
import authReducer from "features/auth/authReducer";
import inviteReducer from "features/invite/inviteReducer";
import venueReducer from "features/venue/venueReducer";
import subscriptionReducer from "features/subscription/subscriptionReducer";
import optoutReducer from "features/optout/optoutReducer";
import reportReducer from "features/report/reportReducer";
import plansReducer from "features/plans/plansReducer";


const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    eventsReducer,
    assetsReducer,
    authReducer,
    inviteReducer,
    venueReducer,
    subscriptionReducer,
    optoutReducer,
    reportReducer,
    plansReducer,
});

export default createRootReducer;
