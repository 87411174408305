const darkTheme = {
    sidebar: {
        primary: {
            background: '#343d44',
            color: '#ffffff',
        },
        secondary: {
            background: '#242b2f',
            color: '#fa8c15',
        },
        iconColor: '#ffffff',
        hoverBackground: '#003366',
    },
    topbar: {
        iconColor: '#ffffff',
    },
    modal: {
        background: '#434f5a',
        border: '#38424B',
        color: '#ededed'
    },
    tabs: {
        border: '#495762',
        color: '#E0E0E0',
    },
    checkbox: {
        background: '#6f8294',
        border: '#6f8294',
        color: '#E0E0E0',
    },
    radio: {
        background: '#6f8294',
        border: '#6f8294',
        color: '#E0E0E0',
    },
    input: {
        background: '#6f8294',
        border: '#6f8294',
        color: '#E0E0E0',
    },
    select: {
        background: '#6f8294',
        border: '#6f8294',
        color: '#E0E0E0',
    },
    button: {
        primary: {
            background: '#323a43',
            color: '#ffffff',
            border: '#323a43',
            hoverBackground: '#262626'
        },
        secondary: {
            background: '#38424b',
            color: '#E0E0E0',
            border: '#38424b',
            hoverBackground: '#323a43',
        }
    },
    card: {
        background: '#434f5a',
        color: '#ededed'
    },
    borderColor: '#495762',

    primaryColor: '#ededed',
    secondaryColor: '#ededed',
    
    primaryBackground: '#38424B',
    secondaryBackground: '#38424B',
};

const semiDarkTheme = {
    sidebar: {
        primary: {
            background: '#003366',
            color: '#038fdd',
        },
        secondary: {
            background: '#032347',
            color: '#fa8c15',
        },
        iconColor: '#ffffff',
        hoverBackground: '#003366',
    },
    topbar: {
        iconColor: '#000000',
    },
    button: {
        primary: {
            background: '#038fde',
            color: '#ffffff',
            border: '#038fde',
            hoverBackground: '#038fde'
        },
        secondary: {
            background: '#ffffff',
            color: '#545454',
            border: '#d9d9d9',
            hoverBackground: '#ffffff',
            hoverColor: '#038fde',
            hoverBorder: '#038fde',
        }
    },
    card: {
        background: '#ffffff',
        color: '#262626'
    },
    borderColor: '#e0e0e0',

    primaryColor: '#545454',
    secondaryColor: '#038fde',
    
    primaryBackground: '#f5f5f5',
    secondaryBackground: '#ffffff',
};

const light = {
    sidebar: {
        primary: {
            background: '#ffffff',
            color: '#545454',
        },
        secondary: {
            background: '#ffffff',
            color: '#038fdd',
        },
        border: '#e0e0e0',
        iconColor: '#545454',
        hoverBackground: '#e0e0e0',
    },
    topbar: {
        iconColor: '#000000',
    },
    button: {
        primary: {
            background: '#038fde',
            color: '#ffffff',
            border: '#038fde',
            hoverBackground: '#038fde'
        },
        secondary: {
            background: '#ffffff',
            color: '#545454',
            border: '#d9d9d9',
            hoverBackground: '#ffffff',
            hoverColor: '#038fde',
            hoverBorder: '#038fde',
        }
    },
    card: {
        background: '#ffffff',
        color: '#262626'
    },
    borderColor: '#e0e0e0',

    primaryColor: '#545454',
    secondaryColor: '#038fde',
    
    primaryBackground: '#f5f5f5',
    secondaryBackground: '#ffffff',
};

export const getTheme = () => {
    const themeEnv = process.env.REACT_APP_THEME;
    if (themeEnv === "dark") {
        return darkTheme;
    } else if (themeEnv === "semi") {
        return semiDarkTheme;
    } else if (themeEnv === "light") {
        return light;
    }
    return semiDarkTheme;
};
