import venueActionTypes from './venueActionTypes';

const getVenue = (payload) => {
    return {
        type: venueActionTypes.GET_VENUE,
        payload,
    };
};

export {
    getVenue,
};
