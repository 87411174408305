import React, { useState } from "react";

import UploadImage from "shared/components/UploadImage";
import Button from "shared/components/Button";

import {
  StyledForm,
  StyledFormItem,
  FlexWrapper,
} from "./styles";

const UploadDocumentsForm = ({ handleSubmit }) => {
  const [ticket, setTicket] = useState(null);
  const [identity, setIdentity] = useState(null);

  const onSubmit = () => {
    handleSubmit({
      ticket,
      identity,
    });
  };

  return (
    <StyledForm>
      <StyledFormItem>
        <FlexWrapper>
          <UploadImage title="Ticket" action={setTicket} />
          <UploadImage title="Identity" action={setIdentity} />
        </FlexWrapper>
      </StyledFormItem>
      <StyledFormItem>
        <Button
          id={`upload_documents_submit_button`}
          onClick={onSubmit}
          type="primary"
          className="gx-mb-0"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Submit
        </Button>
      </StyledFormItem>
    </StyledForm>
  );
};

export default UploadDocumentsForm;
