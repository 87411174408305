import React, { useState } from "react";

import UploadImage from "shared/components/UploadImage";
import Button from "shared/components/Button";
import Checkbox from "shared/components/Checkbox";
import { Input } from "shared/components/Input";
import { RadioButton, RadioGroup } from "shared/components/Radio";

import SeatSelect from "./SeatSelect";
import MapSelect from "./MapSelect";
import {
  StyledForm,
  StyledFormItem,
  FlexWrapper,
  StyledRadioButtonIcon
} from "./styles";

const MatchForm = ({ type, event, seatMap, handleSubmit }) => {
  const [team, setTeam] = useState("home");
  const [concent, setConcent] = useState(false);
  const [ticket, setTicket] = useState(null);
  const [identity, setIdentity] = useState(null);
  const [selectedSeat, setSelectedSeat] = useState(null);

  const handleTeamChange = e => {
    setTeam(e.target.value);
  };

  const handleConcentChange = e => {
    setConcent(e.target.checked);
  };

  const renderTeamSelection = () => {
    if (type === "recheckin" || type === "checkin") {
      return (
        <StyledFormItem>
          <RadioGroup
            defaultValue={team}
            onChange={handleTeamChange}
            buttonStyle="outline"
          >
            <RadioButton id="home_team" value="home">
              <StyledRadioButtonIcon src={event.team_home.logo} />
            </RadioButton>
            <RadioButton id="away_team" value="away">
              <StyledRadioButtonIcon src={event.team_away.logo} />
            </RadioButton>
          </RadioGroup>
        </StyledFormItem>
      );
    }
  };

  const renderConcent = () => {
    if (type === "recheckin" || type === "checkin") {
      return (
        <StyledFormItem>
          <Checkbox checked={concent} onChange={handleConcentChange}>
            <span>
              You agree with{" "}
              <a href="http://snaptivityapp.com/terms-conditions.html">
                Terms of Use
              </a>{" "}
              and give concent to share and use your photos with Ajax, Johan
              Cruijff Arena and their sponsors on their official platforms.
            </span>
          </Checkbox>
        </StyledFormItem>
      );
    }
  };

  const renderDocuments = () => {
    if (type === "recheckin" || type === "optout") {
      return (
        <StyledFormItem>
          <FlexWrapper>
            <UploadImage title="Ticket" action={setTicket} />
            <UploadImage title="Identity" action={setIdentity} />
          </FlexWrapper>
        </StyledFormItem>
      );
    }
  };

  const renderSeatMap = () => {
    if (type === "optout" && event.user_data && event.user_data.seat_id) {
      return (
        <React.Fragment>
          <StyledFormItem>
            <Input value={event.user_data.seat_id} disabled />
          </StyledFormItem>
        </React.Fragment>
      );
    }
    if (seatMap.type === "seat") {
      return (
        <SeatSelect seatMap={seatMap} updateSelectedSeat={setSelectedSeat} />
      );
    } else if (seatMap.type === "map") {
      return (
        <React.Fragment>
          <StyledFormItem>
            <Input value={selectedSeat.id} disabled />
          </StyledFormItem>
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  const onSubmit = () => {
    handleSubmit({
      team,
      concent,
      ticket,
      identity,
      selectedSeat
    });
  };

  if (
    seatMap.type === "map" &&
    (!selectedSeat || !selectedSeat.complete) &&
    !event.user_data
  ) {
    return <MapSelect seatMap={seatMap} updateSelectedSeat={setSelectedSeat} />;
  }
  return (
    <StyledForm>
      {renderSeatMap()}
      {renderTeamSelection()}
      {renderDocuments()}
      {renderConcent()}
      <StyledFormItem>
        <Button
          id={`${type}_submit_button`}
          onClick={onSubmit}
          type="primary"
          className="gx-mb-0"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Submit
        </Button>
      </StyledFormItem>
    </StyledForm>
  );
};

export default MatchForm;
