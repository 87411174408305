import reportActionTypes from './reportActionTypes';

const report = (payload) => {
    return {
        type: reportActionTypes.REPORT,
        payload,
    };
};

export {
    report,
};
