const eventsActionTypes = {
  GET_EVENTS: "GET_EVENTS",
  GET_EVENTS_PENDING: "GET_EVENTS_PENDING",
  GET_EVENTS_FULFILLED: "GET_EVENTS_FULFILLED",
  GET_EVENTS_REJECTED: "GET_EVENTS_REJECTED",

  GET_SEATMAP: "GET_SEATMAP",
  GET_SEATMAP_PENDING: "GET_SEATMAP_PENDING",
  GET_SEATMAP_FULFILLED: "GET_SEATMAP_FULFILLED",
  GET_SEATMAP_REJECTED: "GET_SEATMAP_REJECTED",

  CHECKIN_USER: "CHECKIN_USER",
  CHECKIN_USER_PENDING: "CHECKIN_USER_PENDING",
  CHECKIN_USER_FULFILLED: "CHECKIN_USER_FULFILLED",
  CHECKIN_USER_REJECTED: "CHECKIN_USER_REJECTED",

  RE_CHECKIN_USER: "RE_CHECKIN_USER",
  RE_CHECKIN_USER_PENDING: "RE_CHECKIN_USER_PENDING",
  RE_CHECKIN_USER_FULFILLED: "RE_CHECKIN_USER_FULFILLED",
  RE_CHECKIN_USER_REJECTED: "RE_CHECKIN_USER_REJECTED",

  UPLOAD_DOCUMENTS: "UPLOAD_DOCUMENTS",
  UPLOAD_DOCUMENTS_PENDING: "UPLOAD_DOCUMENTS_PENDING",
  UPLOAD_DOCUMENTS_FULFILLED: "UPLOAD_DOCUMENTS_FULFILLED",
  UPLOAD_DOCUMENTS_REJECTED: "UPLOAD_DOCUMENTS_REJECTED"
};

export default eventsActionTypes;
