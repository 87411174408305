const authActionTypes = {
    SIGNIN: 'SIGNIN',
    SIGNIN_PENDING: 'SIGNIN_PENDING',
    SIGNIN_FULFILLED: 'SIGNIN_FULFILLED',
    SIGNIN_REJECTED: 'SIGNIN_REJECTED',

    SIGNUP: 'SIGNUP',
    SIGNUP_PENDING: 'SIGNUP_PENDING',
    SIGNUP_FULFILLED: 'SIGNUP_FULFILLED',
    SIGNUP_REJECTED: 'SIGNUP_REJECTED',

    GET_CURRENT_AUTHENTICATED_USER: 'GET_CURRENT_AUTHENTICATED_USER',
    SIGNOUT: 'SIGNOUT',
    UPDATE_TOKEN: 'UPDATE_TOKEN',
};

export default authActionTypes;
