import subscriptionActionTypes from './subscriptionActionTypes';

const sendSubscription = (payload) => {
    return {
        type: subscriptionActionTypes.SEND_SUBSCRIPTION,
        payload,
    };
};

export {
    sendSubscription,
};
