import React from "react";
import PropTypes from "prop-types";

import Recheckin from "./recheckin";
import Invite from "./Invite";
import {
  Wrapper,
  FlexWrapper,
  EventLogo,
  SeatDetailsContainer,
  SeatDetailsList,
  SeatDetailsListItem,
  SeatDetailsTitle,
  SeatDetailsValue,
  TabView
} from "./styles";

const propTypes = {
  event: PropTypes.object.isRequired,
  seatMap: PropTypes.object.isRequired,
  verified: PropTypes.bool
};

const EventDetails = ({ event, seatMap, verified }) => {
  const [stand, block, row, seat] = event.user_data.seat_id.split("_");

  const renderRechecin = () => {
    if (!verified) {
      return <Recheckin event={event} seatMap={seatMap} />;
    }
  };

  return (
    <Wrapper>
      <EventLogo src={event.background_images.landscape} alt="" />
      <SeatDetailsContainer>
        <SeatDetailsList>
          <SeatDetailsListItem>
            <SeatDetailsTitle>{stand}</SeatDetailsTitle>
            <SeatDetailsValue>Stand</SeatDetailsValue>
          </SeatDetailsListItem>
          <SeatDetailsListItem>
            <SeatDetailsTitle>{block}</SeatDetailsTitle>
            <SeatDetailsValue>Block</SeatDetailsValue>
          </SeatDetailsListItem>
          <SeatDetailsListItem>
            <SeatDetailsTitle>{row}</SeatDetailsTitle>
            <SeatDetailsValue>Row</SeatDetailsValue>
          </SeatDetailsListItem>
          <SeatDetailsListItem>
            <SeatDetailsTitle>{seat}</SeatDetailsTitle>
            <SeatDetailsValue>Seat</SeatDetailsValue>
          </SeatDetailsListItem>
        </SeatDetailsList>
      </SeatDetailsContainer>
      <FlexWrapper>
        {renderRechecin()}
        <TabView>
          <Invite
            modalView
            event_id={event.id}
            venue_id={process.env.REACT_APP_STADIA_CODE}
          />
        </TabView>
      </FlexWrapper>
    </Wrapper>
  );
};

EventDetails.propTypes = propTypes;

export default EventDetails;
