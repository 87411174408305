import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';

import App from './App';
import "antd/dist/antd.css";
import "./index.css";
import * as serviceWorker from './serviceWorker';

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
        oauth: {
            domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: `${process.env.REACT_APP_HOST_URL}/social`,
            redirectSignOut: `${process.env.REACT_APP_HOST_URL}/signout`,
            responseType: 'code'
        }
    }
});

ReactDOM.render(
    <App />,
    document.getElementById('root')
);

serviceWorker.register();