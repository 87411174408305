import React from "react";
import PropTypes from 'prop-types';
import { Route, Switch } from "react-router-dom";
import EventsPage from "./events";
import GalleryPage from "./gallery";
import PlansPage from "./plans";

const propTypes = {
    match: PropTypes.object.isRequired,
};

const Main = ({ match }) => {
    return (
        <Switch>
            <Route path={`${match.url}/events`} component={EventsPage} />
            <Route path={`${match.url}/gallery/:eventId`} component={GalleryPage} />
            <Route path={`${match.url}/plans`} component={PlansPage} />
        </Switch>
    );
};

Main.propTypes = propTypes;

export default Main;
