import React from "react";
import { Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ConnectedRouter } from "connected-react-router";

import Spinner from "shared/components/Spinner";

import CacheBuster from "./CacheBuster";
import { history, store, persistor } from "./store";
import Routes from "./routes";

const App = () => {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return <Spinner />;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }

        return (
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ConnectedRouter history={history}>
                <Switch>
                  <Route path="/" component={Routes} />
                </Switch>
              </ConnectedRouter>
            </PersistGate>
          </Provider>
        );
      }}
    </CacheBuster>
  );
};

export default App;
