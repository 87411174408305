import reportActionTypes from './reportActionTypes';

const initialState = {
    report: {
        isPending: false,
        isFulfilled: false,
        isRejected: false,
        data: null,
    },
};

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
    case reportActionTypes.REPORT_PENDING:
        return Object.assign({}, state, {
            report: {
                isPending: true,
                isFulfilled: false,
                isRejected: false,
                data: null,
            },
        });
    case reportActionTypes.REPORT_FULFILLED:
        return Object.assign({}, state, {
            report: {
                isPending: false,
                isFulfilled: true,
                isRejected: false,
                data: action.payload,
            },
        });
    case reportActionTypes.REPORT_REJECTED:
        return Object.assign({}, state, {
            report: {
                isPending: false,
                isFulfilled: false,
                isRejected: true,
                data: action.payload,
            },
        });
    default:
        return state;
    }
};

export default reportReducer;
