import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { signin } from 'features/auth/authActions';

import SignIn from './SignIn';

const mapStateToProps = state => ({
    signinData: state.authReducer.signinData,
});

const mapDispatchToProps = dispatch => ({
    signin: params => dispatch(signin(params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));
