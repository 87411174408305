import assetsActionTypes from './assetsActionTypes';

const initialState = {
    assets: {
        isPending: false,
        isFulfilled: false,
        isRejected: false,
        data: null,
    },
    myAsset: {
        isPending: false,
        isFulfilled: false,
        isRejected: false,
        data: null,
    },
};

const assetsReducer = (state = initialState, action) => {
    switch (action.type) {
    case assetsActionTypes.GET_ASSETS_PENDING:
        return Object.assign({}, state, {
            assets: {
                isPending: true,
                isFulfilled: false,
                isRejected: false,
                data: null,
            },
        });
    case assetsActionTypes.GET_ASSETS_FULFILLED:
        return Object.assign({}, state, {
            assets: {
                isPending: false,
                isFulfilled: true,
                isRejected: false,
                data: action.payload,
            },
        });
    case assetsActionTypes.GET_ASSETS_REJECTED:
        return Object.assign({}, state, {
            assets: {
                isPending: false,
                isFulfilled: false,
                isRejected: true,
                data: action.payload,
            },
        });
    case assetsActionTypes.POST_MY_ASSET_PENDING:
        return Object.assign({}, state, {
            myAsset: {
                isPending: true,
                isFulfilled: false,
                isRejected: false,
                data: null,
            },
        });
    case assetsActionTypes.POST_MY_ASSET_FULFILLED:
        return Object.assign({}, state, {
            myAsset: {
                isPending: false,
                isFulfilled: true,
                isRejected: false,
                data: action.payload,
            },
        });
    case assetsActionTypes.POST_MY_ASSET_REJECTED:
        return Object.assign({}, state, {
            myAsset: {
                isPending: false,
                isFulfilled: false,
                isRejected: true,
                data: action.payload,
            },
        });
    default:
        return state;
    }
};

export default assetsReducer;
