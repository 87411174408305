import React from "react";
import map from "lodash/map";

import Plan from "./Plan";
import {
  PageContainer,
  PlansContainer,
  PlansHeader,
  PlansTitle,
  PlansBody
} from "./styles";

const plansConfig = [
  {
    background: "#fadb14",
    color: "#595959",
    title: "ROOKIE",
    width: "33%"
  },
  {
    background: "#038fde",
    color: "#ffffff",
    title: "ALL STAR",
    width: "33%"
  },
  {
    background: "#52c41a",
    color: "#595959",
    title: "HALL OF FAME",
    width: "33%"
  }
];

const PlansPage = ({ plans, subscribePlan }) => {
  const renderPlans = () => {
    return map(plans.data, (plan, index) => {
      return (
        <Plan
          key={index}
          plan={plan}
          config={plansConfig[index]}
          subscribePlan={subscribePlan}
        />
      );
    });
  };

  return (
    <PageContainer>
      <PlansContainer>
        <PlansHeader>
          <PlansTitle>Plans</PlansTitle>
        </PlansHeader>
        <PlansBody>{renderPlans()}</PlansBody>
      </PlansContainer>
    </PageContainer>
  );
};

export default PlansPage;
