import assetsActionTypes from './assetsActionTypes';

const getAssets = (payload) => {
    return {
        type: assetsActionTypes.GET_ASSETS,
        payload,
    };
};

const postMyAsset = (payload) => {
    return {
        type: assetsActionTypes.POST_MY_ASSET,
        payload,
    };
};

export {
    getAssets,
    postMyAsset,
};
