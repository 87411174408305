import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";
import {Link} from 'react-router-dom';
import { DownloadOutlined, LockOutlined } from "@ant-design/icons";

import MoreActions from "features/more/MoreActions";

import { StyledDownloadButton } from "./styles";
import Croppe from "./Croppe";
import Report from "./Report";

const Wrapper = styled.div``;
const CardContainer = styled.div`
  margin-bottom: 20px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
`;
const ImageWrapper = styled.div`
  display: block;
  position: relative;
`;
const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flexwrap: wrap;
  background: rgb(255, 255, 255);
  overflow: hidden;
  borderradius: 3px 0px 0px 3px;
`;
const Image = styled.img`
  width: 100%;
  filter: ${props => props.locked ? 'blur(10px)' : ''};
`;
const LockContainer = styled.div`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const LockLogo = styled(LockOutlined)`
  font-size: 40px;
  color: white;
  display: block;
  margin-bottom: 10px;
`;
const ActionsContainer = styled.div`
  background: white;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;
const ShareIconsContainer = styled.div`
  display: flex;
  height: 32px;
  alignitems: center;
  justifycontent: center;
`;
const VisibleToText = styled.h2`
  color: #ffffff;
  font-size: 20px;
  margin: 0;
  line-height: 25px;
`;

const propTypes = {
  card: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  postMyAsset: PropTypes.func.isRequired,
  locked: PropTypes.bool
};

const PhotoCard = ({ card, event, postMyAsset, locked }) => {
  const [visible, setVisibility] = useState(false);
  const download = image_url => {
    fetch(image_url).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "image.png";
        a.click();
      });
    });
  };
  const shareUrl =
    process.env.REACT_APP_HOST_URL + "/share?photo=" + card.my_photo;

  const renderCropComponent = () => {
    if (!locked) {
      return (
        <Croppe
          imageWithOverlay={card.my_photo}
          image={card.photo}
          overlay={card.overlay}
          postMyAsset={postMyAsset}
          visible={visible}
          setVisibility={setVisibility}
        />
      );
    }
  };

  const renderActionsComponent = () => {
    if (!locked) {
      return (
        <ActionsContainer>
          <ShareIconsContainer>
            <FacebookShareButton
              id="facebook_share_button"
              url={shareUrl}
              style={{ marginRight: "10px", padding: 0, height: "32px" }}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <FacebookMessengerShareButton
              id="messenger_share_button"
              url={shareUrl}
              appId="521270401588372"
              style={{ marginRight: "10px", padding: 0, height: "32px" }}
            >
              <FacebookMessengerIcon size={32} round />
            </FacebookMessengerShareButton>
            <TwitterShareButton
              id="twitter_share_button"
              url={shareUrl}
              style={{ marginRight: "10px", padding: 0, height: "32px" }}
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <StyledDownloadButton
              id="download_button"
              type="primary"
              shape="circle"
              icon={<DownloadOutlined />}
              onClick={() => download(card.my_photo)}
            />
          </ShareIconsContainer>
          <Wrapper>
            <MoreActions>
              <Report event={event} photo={card.photo} />
            </MoreActions>
          </Wrapper>
        </ActionsContainer>
      );
    }
  };

  const renderLockComponent = () => {
    if (locked) {
      return (
        <LockContainer>
          <LockLogo />
          <VisibleToText>Visible to</VisibleToText>
          <VisibleToText>Premium Members</VisibleToText>
          <Link to="/app/plans">View Plans</Link>
        </LockContainer>
      );
    }
  };

  return (
    <CardContainer>
      {renderCropComponent()}
      <ImageWrapper>
        <ImageContainer>
          <Image src={card.photo} alt="" onClick={() => setVisibility(true)} locked={locked} />
        </ImageContainer>
        {renderLockComponent()}
      </ImageWrapper>
      {renderActionsComponent()}
    </CardContainer>
  );
};

PhotoCard.propTypes = propTypes;
export default PhotoCard;
