import eventsActionTypes from "./eventsActionTypes";

const getEvents = payload => {
  return {
    type: eventsActionTypes.GET_EVENTS,
    payload
  };
};

const getSeatMap = payload => {
  return {
    type: eventsActionTypes.GET_SEATMAP,
    payload
  };
};

const checkInUser = payload => {
  return {
    type: eventsActionTypes.CHECKIN_USER,
    payload
  };
};

const reCheckInUser = payload => {
  return {
    type: eventsActionTypes.RE_CHECKIN_USER,
    payload
  };
};

const uploadDocuments = payload => {
  return {
    type: eventsActionTypes.UPLOAD_DOCUMENTS,
    payload
  };
};

export { getEvents, getSeatMap, checkInUser, reCheckInUser, uploadDocuments };
