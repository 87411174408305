import React from "react";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";
import { notification } from "antd";
import { Link } from "react-router-dom";
import {
    GoogleOutlined,
    FacebookOutlined,
  } from '@ant-design/icons';

import CardForm from "features/card/CardForm";
import logo from "shared/assets/images/logo.png";
import Spinner from "shared/components/Spinner";
import Button from "shared/components/Button";
import { Input, PasswordInput } from "shared/components/Input";

import {
  PageContainer,
  Title,
  Logo,
  StyledForm,
  StyledFormItem,
  SocialSection,
  SocialButtonsContainer,
  SocialButton,
  SpanText,
  ActionButtonsContainer,
  ActionLink
} from "../commonStyles";

const propTypes = {
  signinData: PropTypes.object.isRequired,
  signin: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

const SignIn = ({ signin, history, signinData }) => {
  const handleSubmit = values => {
    const onSuccess = () => {
      history.push("/app/events");
    };
    const onError = error => {
      notification.error({
        message: error
      });
    };
    signin({
      email: values.email,
      password: values.password,
      onSuccess,
      onError
    });
  };

  const leftContent = () => {
    return (
      <React.Fragment>
        <Title>Sign In</Title>
        <Logo src={logo} />
      </React.Fragment>
    );
  };

  const rightContent = () => {
    return (
      <StyledForm onFinish={handleSubmit}>
        <StyledFormItem
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!"
            },
            {
              required: true,
              message: "Please input your E-mail!"
            }
          ]}
        >
          <Input placeholder="Email" />
        </StyledFormItem>
        <StyledFormItem
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!"
            }
          ]}
        >
          <PasswordInput placeholder="Password" />
        </StyledFormItem>
        <ActionButtonsContainer>
          <Button
            margin="0 10px 0 0"
            id="sign_in_button"
            type="primary"
            htmlType="submit"
          >
            Sign In
          </Button>
          <SpanText>or</SpanText>
          <Link to="/signup">
            <ActionLink>Sign Up</ActionLink>
          </Link>
        </ActionButtonsContainer>
        <SocialSection>
          <SpanText>or connect with</SpanText>
          <SocialButtonsContainer>
            <SocialButton
              shape="circle"
              icon={<GoogleOutlined />}
              onClick={() => Auth.federatedSignIn({ provider: "Google" })}
            />
            <SocialButton
              shape="circle"
              icon={<FacebookOutlined />}
              onClick={() => Auth.federatedSignIn({ provider: "Facebook" })}
            />
          </SocialButtonsContainer>
        </SocialSection>
      </StyledForm>
    );
  };
  if (signinData.isPending) {
    return <Spinner />;
  }
  return (
    <PageContainer>
      <CardForm
        sideContent={leftContent()}
        sideImage={require("shared/assets/images/stadium.jpg")}
        formContent={rightContent()}
      />
    </PageContainer>
  );
};

SignIn.propTypes = propTypes;

export default SignIn;
