import styled from 'styled-components';

export const FlexWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const StyledForm = styled.div`
`;

export const StyledFormItem = styled.div`
    display: flex;
    padding-bottom: 20px !important; 
    width: 100%;
    justify-content: space-between;
`;

export const StyledRadioButtonIcon = styled.img`
    width: 50px;
    height: 50px;
`;
