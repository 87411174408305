import plansActionTypes from './plansActionTypes';

const getPlans = (payload) => {
    return {
        type: plansActionTypes.GET_PLANS,
        payload,
    };
};

const subscribePlan = (payload) => {
    return {
        type: plansActionTypes.SUBSCRIBE_PLAN,
        payload,
    };
};

export {
    getPlans,
    subscribePlan,
};
