import React from 'react';
import PropTypes from 'prop-types';

import PhotoCard from './PhotoCard';
import MessageCard from './MessageCard';
import EventDetails from './EventDetails';
import Invite from './Invite';

import {
    MobileView,
    Wrapper,
    PageContainer,
    LeftContainer,
    CenterContainer,
    RightContainer,
} from './styles';

const propTypes = {
    cards: PropTypes.array.isRequired,
    seatMap: PropTypes.object.isRequired,
    assetsData: PropTypes.object.isRequired,
    event: PropTypes.object.isRequired,
    postMyAsset: PropTypes.func.isRequired,
};

const GalleryPage = ({
    cards,
    seatMap,
    assetsData,
    event,
    postMyAsset,
}) => {
    const renderPosts = () => {
        return cards.map((card, index) => {
            if (card.type === "photo") {
                return <PhotoCard key={index} card={card} event={event} postMyAsset={postMyAsset} locked={index>2} />;
            } else if (card.type === "message") {
                return <MessageCard key={index} card={card} />;
            }
            return null;
        });
    };

    return (
        <PageContainer>
            <LeftContainer>
                <EventDetails event={event} seatMap={seatMap} verified={assetsData.verified} />
            </LeftContainer>
            <CenterContainer>
                <MobileView>
                    <EventDetails event={event} seatMap={seatMap} verified={assetsData.verified} />
                </MobileView>
                <Wrapper>{renderPosts()}</Wrapper>
            </CenterContainer>
            <RightContainer>
                <Invite event_id={event.id} venue_id={process.env.REACT_APP_STADIA_CODE} />
            </RightContainer>
        </PageContainer>
    );
};

GalleryPage.propTypes = propTypes;

export default GalleryPage;
