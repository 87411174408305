import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";

import WidgetHeader from "features/WidgetHeader/index";
import { inviteFriend } from "features/invite/inviteActions";
import { emailValidator } from "shared/validator";
import {
    INVITE_INVALID_EMAIL,
    INVITE_SENT_SUCCESSFULLY
} from "shared/appConstants";
import { getHeaders } from "shared/apiHelper";

import Button from "shared/components/Button";
import Modal from "shared/components/Modal";
import { Input } from "shared/components/Input";
import { Wrapper } from "./styles";

const propTypes = {
    venue_id: PropTypes.string.isRequired,
    event_id: PropTypes.string.isRequired,
    modalView: PropTypes.bool
};

const Invite = ({ modalView, venue_id, event_id }) => {
    const dispatch = useDispatch();
    const { inviteFriendData } = useSelector(
        ({ inviteReducer }) => inviteReducer
    );
    const { token } = useSelector(({ authReducer }) => authReducer);
    const [visible, setVisibility] = useState(false);
    const [recipient, setRecipient] = useState("");

    const renderInput = () => {
        return (
            <Input
                placeholder="Email Address"
                value={recipient}
                onChange={e => setRecipient(e.target.value)}
            />
        );
    };

    const onSuccess = () => {
        notification.success({
            message: INVITE_SENT_SUCCESSFULLY
        });

        setRecipient("");
        if (modalView) {
            setVisibility(false);
        }
    };

    const onError = error => {
        notification.error({
            message: error
        });

        setRecipient("");
        if (modalView) {
            setVisibility(false);
        }
    };

    const onCancel = () => {
        setVisibility(false);
    };

    const onSubmit = () => {
        if (emailValidator(recipient)) {
            dispatch(
                inviteFriend({
                    data: {
                        venue_id,
                        event_id,
                        recipient_emails: [recipient]
                    },
                    headers: getHeaders(token),
                    onSuccess,
                    onError
                })
            );
        } else {
            notification.error({
                message: INVITE_INVALID_EMAIL
            });
        }
    };

    if (modalView) {
        return (
            <React.Fragment>
                <Button
                    id="invite_button"
                    type="primary"
                    onClick={() => setVisibility(true)}
                >
          Invite
                </Button>
                <Modal
                    title="Invite"
                    visible={visible}
                    onOk={onSubmit}
                    onCancel={onCancel}
                    footer={[
                        <Button
                            margin="0 20px 0 0"
                            id="invite_return"
                            key="back"
                            type="secondary"
                            onClick={onCancel}
                        >
              Return
                        </Button>,
                        <Button
                            id="invite_submit"
                            key="submit"
                            type="primary"
                            loading={inviteFriendData.isPending}
                            onClick={onSubmit}
                        >
              Submit
                        </Button>
                    ]}
                >
                    {renderInput()}
                </Modal>
            </React.Fragment>
        );
    }
    return (
        <Wrapper>
            <WidgetHeader title="Invite" />
            <Wrapper>
                {renderInput()}
                <Button
                    margin="10px 0 0 0"
                    id="invite_button"
                    type="primary"
                    onClick={onSubmit}
                    loading={inviteFriendData.isPending}
                >
          Invite
                </Button>
            </Wrapper>
        </Wrapper>
    );
};

Invite.propTypes = propTypes;
export default Invite;
