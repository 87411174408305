import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    card: PropTypes.object.isRequired,
};

const MessageCard = ({ card }) => {
    return (
        <div style={{ marginBottom: '20px' }}>
            <img
                src={card.img}
                alt=""
                width="100%"
            />
        </div>
    );
};

MessageCard.propTypes = propTypes;
export default MessageCard;
