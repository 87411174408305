import authActionTypes from './authActionTypes';

const getCurrentAuthenticatedUser = (payload) => {
    return {
        type: authActionTypes.GET_CURRENT_AUTHENTICATED_USER,
        payload,
    };
};

const signin = (payload) => {
    return {
        type: authActionTypes.SIGNIN,
        payload,
    };
};

const signup = (payload) => {
    return {
        type: authActionTypes.SIGNUP,
        payload,
    };
};

const signout = () => {
    return {
        type: authActionTypes.SIGNOUT,
    };
};

export {
    getCurrentAuthenticatedUser,
    signin,
    signup,
    signout,
};
