import styled from "styled-components";
import { Button, Modal } from "antd";

export const Wrapper = styled.div`
  width: 100%;
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CropperWrapper = styled.div`
  position: relative;
  height: 520px;
  width: 520px;
  background: #ffffff;

  @media (max-width: 575px) {
    height: 300px;
    width: 300px;
  }
`;

export const StyledDownloadButton = styled(Button)``;

export const EventLogo = styled.img`
  width: 100%;
`;

export const SeatDetailsContainer = styled.div`
  border-bottom: solid 1px #d9d9d9;
  padding-bottom: 20px;
  margin: 15px 0;
  text-align: center !important;
`;

export const SeatDetailsList = styled.ul`
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin: 0 -5px;
`;

export const SeatDetailsListItem = styled.li`
  color: ${props => props.theme.primaryColor};
  font-size: 14px;
  padding: 0 5px;
  width: 25%;
  &:not(:first-child) {
    border-left: solid 1px #d9d9d9;
  }
`;

export const SeatDetailsTitle = styled.span`
  display: block;
  font-size: 14px;
`;

export const SeatDetailsValue = styled.span``;

export const StyledFooterButton = styled(Button)`
  width: 50%;
`;

export const TabView = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: flex;
  }
`;
export const MobileView = styled.div`
  display: none;
  @media (max-width: 575px) {
    display: flex;
    margin-bottom: 20px;
  }
`;

export const PageContainer = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  padding: 32px 32px 0px;
  box-sizing: border-box;
`;
export const LeftContainer = styled.div`
  width: 25%;
  padding-right: 32px;
  height: 100%;
  overflow-x: scroll;
  box-sizing: border-box;
  @media (max-width: 992px) {
    width: 40%;
  }
  @media (max-width: 575px) {
    display: none;
    padding: 0;
  }
`;
export const CenterContainer = styled.div`
  width: 50%;
  padding-right: 32px;
  height: 100%;
  overflow-x: scroll;
  box-sizing: border-box;
  @media (max-width: 992px) {
    width: 60%;
    padding-right: 0;
  }
  @media (max-width: 575px) {
    width: 100%;
    padding-right: 0;
  }
`;
export const RightContainer = styled.div`
  width: 25%;
  height: 100%;
  overflow-x: scroll;
  box-sizing: border-box;
  @media (max-width: 992px) {
    display: none;
  }
`;
