import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getPlans, subscribePlan } from "features/plans/plansActions";
import { getHeaders } from "shared/apiHelper";

import PlansPage from "./PlansPage";

const mapStateToProps = state => ({
  plans: state.plansReducer.plans,
  token: state.authReducer.token
});

const mapDispatchToProps = dispatch => ({
  getPlans: params => dispatch(getPlans(params)),
  subscribePlan: params => dispatch(subscribePlan(params))
});

class PlansPageContainer extends React.PureComponent {
  static propTypes = {
    getPlans: PropTypes.func.isRequired,
    subscribePlan: PropTypes.func.isRequired,
    plans: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    token: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.getPlans();
  }

  getPlans = () => {
    const { token, getPlans } = this.props;
    const headers = getHeaders(token);
    getPlans({
      headers,
      params: {
        venue_id: process.env.REACT_APP_STADIA_CODE
      }
    });
  };

  subscribePlan = payload => {
    const { token, subscribePlan } = this.props;
    const headers = getHeaders(token);
    subscribePlan({
      headers,
      ...payload,
    });
  };

  render() {
    if (this.props.plans.isFulfilled) {
      return (
        <PlansPage
          plans={this.props.plans}
          history={this.props.history}
          getPlans={this.getPlans}
          subscribePlan={this.subscribePlan}
        />
      );
    }
    return null;
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PlansPageContainer)
);
