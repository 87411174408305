import optoutActionTypes from './optoutActionTypes';

const optout = (payload) => {
    return {
        type: optoutActionTypes.OPTOUT,
        payload,
    };
};

export {
    optout,
};
