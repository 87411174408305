import eventsActionTypes from "./eventsActionTypes";

const initialState = {
  events: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  },
  seatMap: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  },
  checkIn: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  },
  reCheckIn: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  },
  uploadDocumentsResponse: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  }
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case eventsActionTypes.GET_EVENTS_PENDING:
      return Object.assign({}, state, {
        events: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case eventsActionTypes.GET_EVENTS_FULFILLED:
      return Object.assign({}, state, {
        events: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case eventsActionTypes.GET_EVENTS_REJECTED:
      return Object.assign({}, state, {
        events: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });

    case eventsActionTypes.GET_SEATMAP_PENDING:
      return Object.assign({}, state, {
        seatMap: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case eventsActionTypes.GET_SEATMAP_FULFILLED:
      return Object.assign({}, state, {
        seatMap: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case eventsActionTypes.GET_SEATMAP_REJECTED:
      return Object.assign({}, state, {
        seatMap: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });

    case eventsActionTypes.CHECKIN_USER_PENDING:
      return Object.assign({}, state, {
        checkIn: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case eventsActionTypes.CHECKIN_USER_FULFILLED:
      return Object.assign({}, state, {
        checkIn: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case eventsActionTypes.CHECKIN_USER_REJECTED:
      return Object.assign({}, state, {
        checkIn: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });

    case eventsActionTypes.RE_CHECKIN_USER_PENDING:
      return Object.assign({}, state, {
        reCheckIn: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case eventsActionTypes.RE_CHECKIN_USER_FULFILLED:
      return Object.assign({}, state, {
        reCheckIn: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case eventsActionTypes.RE_CHECKIN_USER_REJECTED:
      return Object.assign({}, state, {
        reCheckIn: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    case eventsActionTypes.UPLOAD_DOCUMENTS_PENDING:
      return Object.assign({}, state, {
        uploadDocumentsResponse: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case eventsActionTypes.UPLOAD_DOCUMENTS_FULFILLED:
      return Object.assign({}, state, {
        uploadDocumentsResponse: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case eventsActionTypes.UPLOAD_DOCUMENTS_REJECTED:
      return Object.assign({}, state, {
        uploadDocumentsResponse: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    default:
      return state;
  }
};

export default eventsReducer;
