import { call, put, all, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";

import apiPaths from "shared/apiPaths";
import assetsActionTypes from "./assetsActionTypes";
import authActionTypes from "features/auth/authActionTypes";
import { TOKEN_EXPIRED_MESSAGE } from "shared/appConstants";

export function* getAssets(action) {
    const {
        payload: { data, headers, onSuccess, onError }
    } = action;
    const apiConfig = {
        method: "POST",
        url: apiPaths.ASSETS,
        data: data,
        headers: headers
    };

    yield put({ type: assetsActionTypes.GET_ASSETS_PENDING });
    try {
        const response = yield call(axios, apiConfig);
        yield put({
            type: assetsActionTypes.GET_ASSETS_FULFILLED,
            payload: response.data.data
        });
        yield put({
            type: authActionTypes.UPDATE_TOKEN,
            payload: response.data.access_token
        });
        onSuccess && onSuccess(response.data.data);
    } catch (e) {
        const statusCode = e && e.response && e.response.status;
        const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
        if (
            statusCode === 401 &&
      errorMessage === TOKEN_EXPIRED_MESSAGE
        ) {
            yield put({ type: authActionTypes.SIGNOUT });
        }
        yield put({
            type: assetsActionTypes.GET_ASSETS_REJECTED,
            payload: errorMessage
        });
        onError && onError(errorMessage);
    }
}

export function* getAssetsSaga() {
    yield takeLatest(assetsActionTypes.GET_ASSETS, getAssets);
}

export function* postMyAsset(action) {
    const {
        payload: { data, headers, onSuccess, onError }
    } = action;
    const apiConfig = {
        method: "POST",
        url: apiPaths.MY_ASSET,
        data: data,
        headers: headers
    };

    yield put({ type: assetsActionTypes.POST_MY_ASSET_PENDING });
    try {
        const response = yield call(axios, apiConfig);
        yield put({
            type: assetsActionTypes.POST_MY_ASSET_FULFILLED,
            payload: response.data.data
        });
        yield put({
            type: authActionTypes.UPDATE_TOKEN,
            payload: response.data.access_token
        });
        onSuccess && onSuccess();
    } catch (e) {
        const statusCode = e && e.response && e.response.status;
        const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
        if (
            statusCode === 401 &&
      errorMessage === TOKEN_EXPIRED_MESSAGE
        ) {
            yield put({ type: authActionTypes.SIGNOUT });
        }
        yield put({
            type: assetsActionTypes.POST_MY_ASSET_REJECTED,
            payload: errorMessage
        });
        onError && onError(errorMessage);
    }
}

export function* postMyAssetSaga() {
    yield takeLatest(assetsActionTypes.POST_MY_ASSET, postMyAsset);
}

export default function* rootSaga() {
    yield all([fork(getAssetsSaga), fork(postMyAssetSaga)]);
}
