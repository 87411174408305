export const TOKEN_EXPIRED_MESSAGE = "Tokens are invalid.";
export const INVITE_INVALID_EMAIL = "Email Address is invalid.";
export const INVITE_SENT_SUCCESSFULLY = "Invitation sent successfully";

export const REPORT_REASONS = [
  {
    name: "Violence or Dangerous Behavior",
    detail:
      "We take safety of fans very seriously. Please report any images of fights, indecent behaviour and aggression. When Violence or Dangerous behavior is reported, we will remove such content and notify stadium's security."
  },
  {
    name: "Hate symbols",
    detail:
      "Inappropriate gerstures, symbols and images with inappropriate meaning, displays of offence including racism, sexism, specific threats of physical harm and vandalism. We will remove such content and notify club's management"
  },
  {
    name: "Nudity or sexual posture",
    detail:
      "Images displaying sexual intercouse and/or exposed genitals, female nipples, fully nude buttocks, nude or partially nude children. We will remove such content and notify club's management. Children's nudity is taken with extra care, when found inappropriate, fans responsible will be banned from using the service and, subject to club's decision, may be banned from attending future fistures."
  },
  {
    name: "I just don't like",
    detail: "If you don't like the photo and we'll remove it from your feed. "
  }
];
