import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Tag } from "antd";

import Button from "shared/components/Button";
import MoreActions from "features/more/MoreActions";

import OptOut from "./OptOut";
import Checkin from "./Checkin";
import UploadDocuments from "./UploadDocuments";

const propTypes = {
  event: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const Container = styled.div`
  background: ${props => props.theme.card.background};
  margin: 0 0 32px 0;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  opacity: ${props => props.status === "blocked" || props.status === "opt-out" ? '0.6' : ''};
`;
const Image = styled.img`
  filter: ${props => props.status === "blocked" || props.status === "opt-out" ? 'grayscale(100%)' : ''};
  width: 100%;
  max-height: 180px;
  object-fit: cover;
`;
const ActionContainer = styled.div`
  padding: 24px;
  min-height: 130px;
`;
const EventTitle = styled.h2`
  font-size: 16px;
  color: ${props => props.theme.card.color};
  font-weight: 400;
  margin: 0 0 24px 0;
`;

const EventItem = ({ refreshEvents, event, history }) => {
  const { team_home, team_away, background_images, user_data } = event;

  const goToGallery = () => {
    history.push({
      pathname: `/app/gallery/${event.id}`,
      state: { event }
    });
  };
  const renderAction = () => {
    if (user_data) {
      if (user_data.status === "check-in") {
        return (
          <Button id="gallery_button" type="secondary" onClick={goToGallery}>
            Gallery
          </Button>
        );
      } else if (user_data.status === "upload-documents") {
        return (<UploadDocuments event={event} refreshEvents={refreshEvents} />);
      } else {
        return <Tag color="magenta">{user_data.status}</Tag>;
      }
    } else {
      return <Checkin event={event} refreshEvents={refreshEvents} />;
    }
  };

  const renderMoreActions = () => {
    if (
      !(
        user_data &&
        (user_data.status === "opt-out" || user_data.status === "blocked")
      )
    ) {
      return (
        <MoreActions>
          <OptOut event={event} refreshEvents={refreshEvents} />
        </MoreActions>
      );
    }
  };

  return (
    <Container status={user_data && user_data.status}>
      <Image src={background_images.landscape} status={user_data && user_data.status} />
      <ActionContainer>
        <EventTitle>{team_home.name + " VS " + team_away.name}</EventTitle>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          {renderAction()}
          {renderMoreActions()}
        </div>
      </ActionContainer>
    </Container>
  );
};

EventItem.propTypes = propTypes;

export default EventItem;
