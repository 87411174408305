import { call, put, all, fork, takeLatest } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';

import authActionTypes from './authActionTypes';

export function* getCurrentAuthenticatedUser(action) {
    const { payload: { onSuccess, onError } } = action;
    yield put({ type: authActionTypes.SIGNIN_PENDING });
    try {
        const response = yield call(
            [Auth, 'currentAuthenticatedUser'],
        );
        yield put({
            type: authActionTypes.SIGNIN_FULFILLED,
            payload: response
        });
        onSuccess && onSuccess();
    // eslint-disable-next-line no-empty
    } catch (e) {
        onError && onError(e.message);
    }
}

export function* getCurrentAuthenticatedUserSaga() {
    yield takeLatest(authActionTypes.GET_CURRENT_AUTHENTICATED_USER, getCurrentAuthenticatedUser);
}

export function* signin(action) {
    const { payload: { email, password, onSuccess, onError } } = action;
    yield put({ type: authActionTypes.SIGNIN_PENDING });
    try {
        const response = yield call(
            [Auth, 'signIn'],
            email,
            password
        );
        yield put({
            type: authActionTypes.SIGNIN_FULFILLED,
            payload: response
        });
        onSuccess && onSuccess();
    } catch (e) {
        yield put({
            type: authActionTypes.SIGNIN_REJECTED,
            payload: e.message
        });
        onError && onError(e.message);
    }
}

export function* signinSaga() {
    yield takeLatest(authActionTypes.SIGNIN, signin);
}

export function* signup(action) {
    const { payload: { data, onSuccess, onError } } = action;
    yield put({ type: authActionTypes.SIGNUP_PENDING });
    try {
        const response = yield call([Auth, 'signUp'], data);
        yield put({
            type: authActionTypes.SIGNUP_FULFILLED,
            payload: response.data
        });
        onSuccess && onSuccess();
    } catch (e) {
        yield put({
            type: authActionTypes.SIGNUP_REJECTED,
            payload: e.message
        });
        onError && onError(e.message);
    }
}

export function* signupSaga() {
    yield takeLatest(authActionTypes.SIGNUP, signup);
}

export default function* rootSaga() {
    yield all([fork(signinSaga), fork(signupSaga), fork(getCurrentAuthenticatedUserSaga)]);
}
