import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getEvents } from 'features/events/eventsActions';
import { getHeaders } from 'shared/apiHelper';

import EventsPage from './EventsPage';

const mapStateToProps = state => ({
    events: state.eventsReducer.events,
    token: state.authReducer.token,
});

const mapDispatchToProps = dispatch => ({
    getEvents: params => dispatch(getEvents(params)),
});


class EventsPageContainer extends React.PureComponent {
    static propTypes = {
        getEvents: PropTypes.func.isRequired,
        events: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        token: PropTypes.object.isRequired,
    }

    componentDidMount() {
        this.getEvents();
    }

    getEvents = () => {
        const { token, getEvents } = this.props;
        const headers = getHeaders(token);
        getEvents({
            headers,
            params: {
                venue_id: process.env.REACT_APP_STADIA_CODE
            }
        });
    }

    render() {
        if (this.props.events.isFulfilled) {
            return (
                <EventsPage
                    events={this.props.events}
                    history={this.props.history}
                    getEvents={this.getEvents}
                />
            );
        }
        return null;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventsPageContainer));
