import styled from 'styled-components';

export const PageContainer = styled.div`
  padding: 32px 32px 0;
`;

export const PlansContainer = styled.div`
  background: #ffffff;
`;
export const PlansHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: #535353;
  font-weight: 500;
  font-size: 16px;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
  zoom: 1;
`;
export const PlansTitle = styled.span``;
export const PlansBody = styled.div`
  display: flex;
  padding: 24px;
`;

export const PlanWrapper = styled.div`
  margin: 16px;
  width: ${props => props.width || "auto"};
  border-radius: 10px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  transition: all 0.2s ease-in-out;
`;
export const PlanHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 224px;
  width: 224px;
  margin: 40px auto 0;
  background: ${props => props.background || "#fadb14"};
  color: ${props => props.color || "#595959"};
`;
export const PlanTitle = styled.span`
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  letter-spacing: 3px;
  font-feature-settings: tnum;
`;
export const PlanBody = styled.div`
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const FeatureList = styled.ul`
  list-style: none;
  margin: 0 0 40px;
  padding: 0;
`;
export const FeatureListItem = styled.li``;
export const FeatureText = styled.span`
  margin-left: 15px;
`;