import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { footerText } from "shared/util/config";
import Pages from "pages/index";
import { useRouteMatch } from "react-router-dom";

import { sendSubscription } from "features/subscription/subscriptionActions";
import { getHeaders } from "shared/apiHelper";
import SidebarNav from "./SidebarNav";
import Topbar from "./Topbar";
import {
  registerServiceWorker,
  isPushNotificationSupported,
  askUserPermission,
  createNotificationSubscription,
  getUserSubscription
} from "./pushNotifications";

const AppContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
`;
const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;
const Content = styled.div`
  background: ${props => props.theme.primaryBackground};
  width: 100%;
  height: calc(100vh - 122px);
  overflow: scroll;
  box-sizing: border-box;
  @media (max-width: 575px) {
    height: calc(100vh - 100px);
  }
`;
const FooterContainer = styled.div`
  border-top: solid 1px ${props => props.theme.borderColor};
  padding: 14px 32px;
  color: ${props => props.theme.primaryColor};
  font-size: 14px;
  background: ${props => props.theme.secondaryBackground};
  box-sizing: border-box;
`;

const MainApp = () => {
  const [consent, setConsent] = useState(Notification.permission);
  const dispatch = useDispatch();
  const { token, signinData } = useSelector(({ authReducer }) => authReducer);
  const pushNotificationSupported = isPushNotificationSupported();
  const match = useRouteMatch();

  useEffect(() => {
    if (pushNotificationSupported) {
      registerServiceWorker();
    }
    if (consent === "default") {
      askUserPermission().then(consent => {
        setConsent(consent);
      });
    }
    if (consent === "granted") {
      send();
    }
  }, [consent]);

  const send = async () => {
    const existingSubscription = await getUserSubscription();
    if (!existingSubscription) {
      createNotificationSubscription().then(subscription => {
        dispatch(
          sendSubscription({
            headers: getHeaders(token),
            data: {
              subscription: JSON.parse(JSON.stringify(subscription, null, " "))
            }
          })
        );
      });
    }
  };

  return (
    <AppContainer>
      <SidebarNav />
      <ContentContainer>
        <Topbar />
        <Content>
          <Switch>
            <Route path={`${match.url}app`} component={Pages} />
          </Switch>
        </Content>
        <FooterContainer>{footerText}</FooterContainer>
      </ContentContainer>
    </AppContainer>
  );
};

export default MainApp;
