import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  .ant-modal-close-x {
    color: ${props =>
      props.theme.modal ? props.theme.modal.color : ""} !important;
  }
  .ant-modal-header {
    background: ${props =>
      props.theme.modal ? props.theme.modal.border : ""} !important;
    border-color: ${props =>
      props.theme.modal ? props.theme.modal.border : ""} !important;
  }
  .ant-modal-footer {
    background: ${props =>
      props.theme.modal ? props.theme.modal.background : ""} !important;
    border-color: ${props =>
      props.theme.modal ? props.theme.modal.border : ""} !important;
  }
  .ant-modal-title {
    color: ${props =>
      props.theme.modal ? props.theme.modal.color : ""} !important;
  }
  .ant-modal-body {
    background: ${props =>
      props.theme.modal ? props.theme.modal.background : ""} !important;
    color: ${props =>
      props.theme.modal ? props.theme.modal.color : ""} !important;
  }
  
  .ant-modal-content {
    @media (max-width: 575px) {
      max-width: 300px;
    }
  }
`;

export default StyledModal;
