import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Cropper from "react-easy-crop";

import Modal from "shared/components/Modal";

import getCroppedImg from "./cropImage";
import {
  Wrapper,
  FlexWrapper,
  CropperWrapper,
  StyledFooterButton
} from "./styles";

const propTypes = {
  imageWithOverlay: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  overlay: PropTypes.string.isRequired,
  postMyAsset: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  setVisibility: PropTypes.func.isRequired,
};

const Croppe = ({ imageWithOverlay, image, overlay, postMyAsset, setVisibility, visible }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (img, oSrc) => {
      try {
        const croppedImage = await getCroppedImg(
          img,
          oSrc,
          croppedAreaPixels,
          0
        );
        postMyAsset({
          data: {
            my_asset: imageWithOverlay.split("?")[0],
            new_my_asset: croppedImage
          },
          onSuccess: () => {
            setVisibility(false);
          }
        });
        setCroppedImage(croppedImage);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    },
    [croppedAreaPixels, 0]
  );

  const onOk = () => {
    showCroppedImage(image, overlay);
  };

  return (
    <Wrapper>
      <Modal
        visible={visible}
        closable={false}
        centered={true}
        bodyStyle={{ padding: 0 }}
        footer={
          <FlexWrapper>
            <StyledFooterButton
              id="crop_back"
              key="back"
              onClick={() => {
                setVisibility(false);
              }}
            >
              Cancel
            </StyledFooterButton>
            ,
            <StyledFooterButton
              id="crop_submit"
              key="submit"
              type="primary"
              onClick={onOk}
            >
              Crop
            </StyledFooterButton>
          </FlexWrapper>
        }
        onCancel={() => {
          setVisibility(false);
        }}
      >
        <CropperWrapper>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            showGrid={false}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            style={{
              cropAreaStyle: {
                backgroundImage: `url('${overlay}')`,
                backgroundSize: "cover",
                border: "none"
              }
            }}
          />
        </CropperWrapper>
      </Modal>
    </Wrapper>
  );
};

Croppe.propTypes = propTypes;

export default Croppe;
