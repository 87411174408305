import styled from 'styled-components';
import { Input as Inp } from 'antd';

export const Input = styled(Inp)`
    background: ${props => props.theme.input ? props.theme.input.background : ''} !important;
    border-color: ${props => props.theme.input ? props.theme.input.border : ''} !important;
    color: ${props => props.theme.input ? props.theme.input.color : ''} !important;
`;

export const PasswordInput = styled(Inp.Password)`
    .ant-input {
        background: ${props => props.theme.input ? props.theme.input.background : ''} !important;
        border-color: ${props => props.theme.input ? props.theme.input.border : ''} !important;
        color: ${props => props.theme.input ? props.theme.input.color : ''} !important;
    }
`;