import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const ActionContainer = styled.div`
  display: flex;
  max-width: 680px;
  width: 94%;
  margin: auto;
  background: ${props => props.theme.card.background};
  border-radius: 12px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  overflow: hidden;
  @media (max-width: 575px) {
    flex-direction: column;
  }
`;
const LeftSection = styled.div`
  width: 40%;
  min-height: 200px;
  background-image: ${props => `url("${props.image}")`};
  background-size: cover;
  background-position: center top;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px 35px 20px;
  color: ${props => props.theme.card.color};
  @media (max-width: 575px) {
    width: 100%;
  }
`;
const RightSection = styled.div`
  width: 60%;
  padding: 35px 35px 20px;
  color: ${props => props.theme.card.color};
  @media (max-width: 575px) {
    width: 100%;
  }
`;

const propTypes = {
    sideContent: PropTypes.object,
    formContent: PropTypes.object,
    sideImage: PropTypes.any.isRequired,
};

const CardForm = ({
    sideContent,
    sideImage,
    formContent
}) => {
    return (
        <ActionContainer>
            <LeftSection image={sideImage}>
                {sideContent}
            </LeftSection>
            <RightSection>
                {formContent}
            </RightSection>
        </ActionContainer>
    );
};

CardForm.propTypes = propTypes;

export default CardForm;
