import React from "react";
import styled from "styled-components";
import { ReactSVG } from "react-svg";
import { Spin } from "antd";

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`;

class MapSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentOptions: null,
      stand: null,
      block: null
    };
  }

  componentDidMount() {
    document.getElementById("svg").addEventListener("click", this.clicked);
  }

  clicked = e => {
    const { block, stand } = this.state;
    if (
      this.state.stand === null &&
      e.target.getAttribute("data") in this.props.seatMap.stand
    ) {
      this.updateSelectedSeat({
        stand: e.target.getAttribute("data"),
        block
      });
      this.setState({
        stand: e.target.getAttribute("data")
      });
    } else if (
      this.state.stand !== null &&
      this.state.block === null &&
      (e.target.parentNode.getAttribute("data") === "select_group" ||
        e.target.parentNode.parentNode.getAttribute("data") === "select_group")
    ) {
      this.updateSelectedSeat({
        stand,
        block: e.target.getAttribute("data")
      });
      this.setState({
        block: e.target.getAttribute("data")
      });
    }
  };

  updateSelectedSeat = value => {
    this.props.updateSelectedSeat({
      ...value,
      id: `${value.stand}_${value.block}` ,
      complete: value.stand && value.block
    });
  };

  render() {
    return (
      <ImageContainer>
        <ReactSVG
          id="svg"
          className="svg-image"
          src={
            this.state.stand === null
              ? this.props.seatMap.map
              : this.props.seatMap.stand[this.state.stand].map
          }
          loading={Spin}
        />
      </ImageContainer>
    );
  }
}

export default MapSelect;
