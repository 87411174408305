import React, { useState } from "react";
import styled from "styled-components";
import { Drawer, Button } from "antd";
import {
  MenuUnfoldOutlined,
} from '@ant-design/icons';


import logo from "shared/assets/images/logo.png";
import textLogo from "shared/assets/images/logo_white.png";
import Nav from "./Nav";

const TopbarContainer = styled.div`
  background: ${props => props.theme.secondaryBackground};
  box-shadow: 6px 0 4px 4px rgba(0, 0, 0, 0.08);
  height: 72px;
  padding: 0 15px;
  position: relative;
  z-index: 10;

  @media (max-width: 575px) {
    height: 50px;
  }
`;

const LogoContainer = styled.div`
  display: none;
  align-items: center;
  width: 100%;
  height: 100%;
  @media (max-width: 991px) {
    display: flex;
  }
`;

const Logo = styled.img`
  width: 35px;
  height: 35px;
  margin-left: 20px;
`;

const StyledButton = styled(Button)`
  margin: 0 !important;
  background: transparent !important;
  border: none !important;
  width: 50px;
  color: ${props => props.theme.topbar.iconColor} !important;

  &:hover {
    background: ${props => props.theme.sidebar.hoverBackground} !important;
  }
`;
const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0 !important;
  }
`;
const DrawerContainer = styled.div`
  background: ${props => props.theme.sidebar.primary.background};
  height: 100vh;
`;
const DrawerLogoContainer = styled.div`
  background: ${props => props.theme.sidebar.secondary.background};
  height: 72px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 575px) {
    height: 50px;
  }
`;
const DrawerLogo = styled.img`
  width: 140px;
  height: 50px;
`;

const TopBar = () => {
    const [visible, setVisibility] = useState(false);
    return (
        <TopbarContainer>
            <LogoContainer>
                <StyledButton
                    size="large"
                    icon={<MenuUnfoldOutlined />}
                    onClick={() => setVisibility(true)}
                />
                <Logo src={logo} />
            </LogoContainer>
            <StyledDrawer
                title="Basic Drawer"
                placement="left"
                closable={false}
                onClose={() => setVisibility(false)}
                visible={visible}
                headerStyle={{ display: "none" }}
            >
                <DrawerContainer>
                    <DrawerLogoContainer>
                        <DrawerLogo src={textLogo} />
                    </DrawerLogoContainer>
                    <Nav />
                </DrawerContainer>
            </StyledDrawer>
        </TopbarContainer>
    );
};

export default TopBar;
