import plansActionTypes from "./plansActionTypes";

const initialState = {
  plans: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  },
  subscribePlanData: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  }
};

const plansReducer = (state = initialState, action) => {
  switch (action.type) {
    case plansActionTypes.GET_PLANS_PENDING:
      return Object.assign({}, state, {
        plans: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case plansActionTypes.GET_PLANS_FULFILLED:
      return Object.assign({}, state, {
        plans: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case plansActionTypes.GET_PLANS_REJECTED:
      return Object.assign({}, state, {
        plans: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    case plansActionTypes.SUBSCRIBE_PLAN_PENDING:
      return Object.assign({}, state, {
        subscribePlanData: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case plansActionTypes.SUBSCRIBE_PLAN_FULFILLED:
      return Object.assign({}, state, {
        subscribePlanData: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case plansActionTypes.SUBSCRIBE_PLAN_REJECTED:
      return Object.assign({}, state, {
        subscribePlanData: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    default:
      return state;
  }
};

export default plansReducer;
