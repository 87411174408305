const baseUrl = process.env.REACT_APP_API_URL;

const apiPaths = {
    CONFIG: (eventID) => `${baseUrl}/api/config/${eventID}`,
    VENUE: `${baseUrl}/api/venue`,
    EVENTS: `${baseUrl}/api/events`,
    UPLOAD_DOCUMENTS: `${baseUrl}/api/uploadDoc`,
    SEATMAP: `${baseUrl}/api/seatmap`,
    REPORT: `${baseUrl}/api/report`,
    PLANS: `${baseUrl}/api/plans`,
    SUBSCRIBE_PLAN: `${baseUrl}/api/pay`,
    CHECKIN: `${baseUrl}/api/checkin`,
    RE_CHECKIN: `${baseUrl}/api/recheckin`,
    ASSETS: `${baseUrl}/api/getasset`,
    MY_ASSET: `${baseUrl}/api/myasset`,
    INVITE_FRIEND: `${baseUrl}/api/invitefriend`,
    USER_SUBSCRIBE: `${baseUrl}/api/userSubscribe`,
    OPTOUT: `${baseUrl}/api/optout`,
};

export default apiPaths;
