import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { signup } from 'features/auth/authActions';

import SignUp from './SignUp';

const mapStateToProps = state => ({
    signupData: state.authReducer.signupData,
});

const mapDispatchToProps = dispatch => ({
    signup: params => dispatch(signup(params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp));
