import subscriptionActionTypes from './subscriptionActionTypes';

const initialState = {
    subscription: {
        isPending: false,
        isFulfilled: false,
        isRejected: false,
        data: null,
    },
};

const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
    case subscriptionActionTypes.SEND_SUBSCRIPTION_PENDING:
        return Object.assign({}, state, {
            subscription: {
                isPending: true,
                isFulfilled: false,
                isRejected: false,
                data: null,
            },
        });
    case subscriptionActionTypes.SEND_SUBSCRIPTION_FULFILLED:
        return Object.assign({}, state, {
            subscription: {
                isPending: false,
                isFulfilled: true,
                isRejected: false,
                data: action.payload,
            },
        });
    case subscriptionActionTypes.SEND_SUBSCRIPTION_REJECTED:
        return Object.assign({}, state, {
            subscription: {
                isPending: false,
                isFulfilled: false,
                isRejected: true,
                data: action.payload,
            },
        });
    default:
        return state;
    }
};

export default subscriptionReducer;
