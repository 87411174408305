import React from "react";
import styled from "styled-components";
import { Spin } from "antd";

const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledSpin = styled(Spin)``;

const Spinner = () => {
  return (
    <SpinnerWrapper>
      <StyledSpin />
    </SpinnerWrapper>
  );
};

export default Spinner;
