import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';

import logo from "shared/assets/images/logo_white.png";
import Nav from './Nav';

const SidebarContainer = styled.div`
  width: ${props => props.collapsed ? "80px" : "280px"};
  height: 100%;
  background: ${props => props.theme.sidebar.primary.background};
  transition: 0.2s;
  box-sizing: border-box;
  @media (max-width: 991px) {
    display: none;
  }
`;

const NavContainer = styled.div`
  height: calc(100% - 72px);
  border-right: ${props => props.theme.sidebar.border ? `1px solid ${props.theme.sidebar.border}` : 'none'};
  overflow-x: scroll;
  box-sizing: border-box;
`;

const LogoContainer = styled.div`
  background: ${props => props.theme.sidebar.secondary.background};
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  width: 100%;
  height: 72px;
  padding: 18.5px;
  box-sizing: border-box;
`;

const Logo = styled.img`
  display: ${props => props.collapsed ? "none" : ""};
  width: 140px;
  height: 50px;
  margin-left: 20px;
`;

const StyledButton = styled(Button)`
  margin: 0 !important;
  background: transparent !important;
  border: none !important;
  color: ${props => props.theme.sidebar.iconColor} !important;
  width: 50px;

  &:hover {
    background: ${props => props.theme.sidebar.hoverBackground} !important;
  }
`;

const SidebarNav = () => {
    const [collapsed, setCollapsed] = useState(false);
    return (
        <SidebarContainer collapsed={collapsed}>
            <LogoContainer>
                <StyledButton
                    size="large"
                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    onClick={() => setCollapsed(!collapsed)}
                />
                <Logo src={logo} collapsed={collapsed} />
            </LogoContainer>
            <NavContainer>
                <Nav collapsed={collapsed} />
            </NavContainer>
        </SidebarContainer>
    );
};

export default SidebarNav;
