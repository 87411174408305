const plansActionTypes = {
    GET_PLANS: 'GET_PLANS',
    GET_PLANS_PENDING: 'GET_PLANS_PENDING',
    GET_PLANS_FULFILLED: 'GET_PLANS_FULFILLED',
    GET_PLANS_REJECTED: 'GET_PLANS_REJECTED',

    SUBSCRIBE_PLAN: 'SUBSCRIBE_PLAN',
    SUBSCRIBE_PLAN_PENDING: 'SUBSCRIBE_PLAN_PENDING',
    SUBSCRIBE_PLAN_FULFILLED: 'SUBSCRIBE_PLAN_FULFILLED',
    SUBSCRIBE_PLAN_REJECTED: 'SUBSCRIBE_PLAN_REJECTED',
};

export default plansActionTypes;
