import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ReactSVG } from "react-svg";
import { Spin } from "antd";

import CheckInForm from "./CheckInForm";

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`;

class MapCheckin extends React.PureComponent {
  static propTypes = {
      seatMap: PropTypes.object.isRequired,
      event: PropTypes.object.isRequired,
      handleSubmit: PropTypes.func.isRequired
  };

  constructor(props) {
      super(props);
      this.state = {
          stand: null,
          block: null
      };
  }

  componentDidMount() {
      document.getElementById("svg").addEventListener("click", this.clicked);
  }

  clicked = e => {
      if (
          this.state.stand === null &&
      e.target.getAttribute("data") in this.props.seatMap.data.stand
      ) {
          this.setState({
              stand: e.target.getAttribute("data")
          });
      } else if (
          this.state.stand !== null &&
      this.state.block === null &&
      (e.target.parentNode.getAttribute("data") === "select_group" ||
        e.target.parentNode.parentNode.getAttribute("data") === "select_group")
      ) {
          this.setState({
              block: e.target.getAttribute("data")
          });
      }
  };

  renderMap = () => {
      return (
          <ImageContainer>
              <ReactSVG
                  id="svg"
                  className="svg-image"
                  src={
                      this.state.stand === null
                          ? this.props.seatMap.data.map
                          : this.props.seatMap.data.stand[this.state.stand].map
                  }
                  loading={Spin}
              />
          </ImageContainer>
      );
  };

  renderForm = () => {
      const { event, handleSubmit } = this.props;
      const { stand, block } = this.state;
      const newSeatMap = {
          stand,
          block
      };
      return (
          <CheckInForm
              type="map"
              seatMap={newSeatMap}
              event={event}
              handleSubmit={handleSubmit}
          />
      );
  };

  render() {
      const { stand, block } = this.state;
      if (stand !== null && block !== null) {
          return this.renderForm();
      } else {
          return this.renderMap();
      }
  }
}

export default MapCheckin;
