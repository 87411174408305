const assetsActionTypes = {
    GET_ASSETS: 'GET_ASSETS',
    GET_ASSETS_PENDING: 'GET_ASSETS_PENDING',
    GET_ASSETS_FULFILLED: 'GET_ASSETS_FULFILLED',
    GET_ASSETS_REJECTED: 'GET_ASSETS_REJECTED',

    POST_MY_ASSET: 'POST_MY_ASSET',
    POST_MY_ASSET_PENDING: 'POST_MY_ASSET_PENDING',
    POST_MY_ASSET_FULFILLED: 'POST_MY_ASSET_FULFILLED',
    POST_MY_ASSET_REJECTED: 'POST_MY_ASSET_REJECTED',
};

export default assetsActionTypes;
