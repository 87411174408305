import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { notification } from 'antd';

import Button from 'shared/components/Button';
import Modal from 'shared/components/Modal';
import { getHeaders } from 'shared/apiHelper';
import { reCheckInUser } from 'features/events/eventsActions';

import CheckInForm from './CheckInForm';
import MapCheckin from './MapCheckin';

import {
    Wrapper,
} from '../styles';

const propTypes = {
    event: PropTypes.object.isRequired,
    seatMap: PropTypes.object.isRequired,
};

const Recheckin = ({ event, seatMap }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { token } = useSelector(({ authReducer }) => authReducer);
    const [visible, setVisibility] = useState(false);

    const handleSubmit = (data) => {
        const onSuccess = () => {
            notification.success({
                message: "Recheckin request sent successfully"
            });
            setVisibility(false);
            history.push("/app/events");
        };
    
        const onError = error => {
            notification.error({
                message: error
            });
        };

        dispatch(reCheckInUser({
            headers: getHeaders(token),
            data,
            onSuccess,
            onError
        }));
    };

    const renderModalContent = () => {
        if (seatMap.data.type === "seat") {
            return (
                <CheckInForm type="seat" seatMap={seatMap} event={event} handleSubmit={handleSubmit} />
            );
        } else if (seatMap.data.type === "map") {
            return (
                <MapCheckin event={event} seatMap={seatMap} handleSubmit={handleSubmit} />
            );
        }
        return null;
    };

    return (
        <Wrapper>
            <Button id="recheckin_button" type="primary" onClick={() => setVisibility(true)}>Recheckin</Button>
            <Modal
                title="Recheckin"
                visible={visible}
                footer={null}
                onCancel={() => {
                    setVisibility(false);
                }}
            >
                {renderModalContent()}
            </Modal>
        </Wrapper>
    );
};

Recheckin.propTypes = propTypes;

export default Recheckin;
