import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import rootSaga from "./sagas";
import createRootReducer from './rootReducer';

const createBrowserHistory = require('history').createBrowserHistory;
const history = createBrowserHistory();
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['authReducer', 'venueReducer']
};
const rootReducer = createRootReducer(history);
const persistedReducer = persistReducer(persistConfig, rootReducer);
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, routeMiddleware];

const store = createStore(
    persistedReducer, // root reducer with router state
    compose(
        applyMiddleware(
            routerMiddleware(history), // for dispatching history actions
            ...middlewares
        ),
    ),
);
sagaMiddleware.run(rootSaga);
const persistor = persistStore(store);

export {
    history,
    store,
    persistor,
};
