import React from "react";
import styled from 'styled-components';
import PropTypes from "prop-types";

const Title = styled.h2`
    color: ${props => props.theme.primaryColor};
    border-bottom: solid 1px ${props => props.theme.primaryColor};
    padding-bottom: 15px;
    margin-bottom: 20px;
    font-size: 16px;
    position: relative;

    :before {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        z-index: 1;
        height: 3px;
        width: 42px;
        background-color: #038fde;
    }
`;

const WidgetHeader = ({ title }) => {
    return (
        <Title>
            {title}
        </Title>
    );
};

WidgetHeader.propTypes = {
    title: PropTypes.node,
};

export default WidgetHeader;
