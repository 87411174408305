import React, { useState } from "react";
import PropTypes from "prop-types";
import { notification } from "antd";

import Button from "shared/components/Button";
import { Input } from "shared/components/Input";
import { RadioButton, RadioGroup } from "shared/components/Radio";
import Checkbox from "shared/components/Checkbox";
import SeatSelect from "features/checkin/SeatSelect";
import UploadImage from "shared/components/UploadImage";

import {
    FlexWrapper,
    StyledForm,
    StyledFormItem,
    StyledRadioButtonIcon,
} from "./styles";

const propTypes = {
    type: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    event: PropTypes.object.isRequired,
    seatMap: PropTypes.object.isRequired
};

const CheckInForm = ({ type, handleSubmit, event, seatMap }) => {
    const [team, setTeam] = useState("home");
    const [concent, setConcent] = useState(false);
    const [ticket, setTicket] = useState(null);
    const [identity, setIdentity] = useState(null);
    const [selectedSeat, setSelectedSeat] = useState(null);

    const handleTeamChange = e => {
        setTeam(e.target.value);
    };

    const handleConcentChange = e => {
        setConcent(e.target.checked);
    };

    const onSubmit = () => {
        let new_seat_id = "";
        let errorMessage = "";
        if (type === "seat") {
            new_seat_id = `${selectedSeat.stand}_${selectedSeat.block}_${selectedSeat.row}_${selectedSeat.seat}`;
            if (!selectedSeat.stand) {
                errorMessage = "Please select a stand";
            } else if (!selectedSeat.block) {
                errorMessage = "Please select a block";
            } else if (!selectedSeat.row) {
                errorMessage = "Please select a row";
            } else if (!selectedSeat.seat) {
                errorMessage = "Please select a seat";
            }
        } else if (type === "map") {
            new_seat_id = `${seatMap.stand}_${seatMap.block}`;
        }
        if (!concent) {
            errorMessage = "Please agree with terms of use";
        } else if (!ticket) {
            errorMessage = "Please upload a ticket";
        } else if (!identity) {
            errorMessage = "Please upload a identity";
        }

        if (errorMessage) {
            notification.error({
                message: errorMessage
            });
        } else {
            handleSubmit({
                venue_id: process.env.REACT_APP_STADIA_CODE,
                event_id: event.id,
                new_seat_id,
                user_team: team,
                ticket_img: ticket,
                user_identity_img: identity
            });
        }
    };

    const renderSeatMap = () => {
        if (type === "seat") {
            return (
                <SeatSelect seatMap={seatMap.data} updateSelectedSeat={setSelectedSeat} />
            );
        } else if (type === "map") {
            return (
                <React.Fragment>
                    <StyledFormItem>
                        <Input value={seatMap.stand} disabled />
                    </StyledFormItem>
                    <StyledFormItem>
                        <Input value={seatMap.block} disabled />
                    </StyledFormItem>
                </React.Fragment>
            );
        } else {
            return null;
        }
    };

    return (
        <StyledForm>
            {renderSeatMap()}
            <StyledFormItem>
                <RadioGroup
                    defaultValue={team}
                    onChange={handleTeamChange}
                    buttonStyle="outline"
                >
                    <RadioButton id="home_team" value="home">
                        <StyledRadioButtonIcon src={event.team_home.logo} />
                    </RadioButton>
                    <RadioButton id="away_team" value="away">
                        <StyledRadioButtonIcon src={event.team_away.logo} />
                    </RadioButton>
                </RadioGroup>
            </StyledFormItem>
            <StyledFormItem>
                <FlexWrapper>
                    <UploadImage title="Ticket" action={setTicket} />
                    <UploadImage title="Identity" action={setIdentity} />
                </FlexWrapper>
            </StyledFormItem>
            <StyledFormItem>
                <Checkbox checked={concent} onChange={handleConcentChange}>
                    <span>
            You agree with{" "}
                        <a href="http://snaptivityapp.com/terms-conditions.html">
              Terms of Use
                        </a>{" "}
            and give concent to share and use your photos with Ajax, Johan
            Cruijff Arena and their sponsors on their official platforms.
                    </span>
                </Checkbox>
            </StyledFormItem>
            <StyledFormItem>
                <Button
                    id="checkin_button"
                    onClick={onSubmit}
                    type="primary"
                    className="gx-mb-0"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
          Checkin
                </Button>
            </StyledFormItem>
        </StyledForm>
    );
};

CheckInForm.propTypes = propTypes;

export default CheckInForm;
