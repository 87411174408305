import React, { memo } from "react";
import { connect } from "react-redux";
import { ThemeProvider } from "styled-components";
import PropTypes from "prop-types";
import {
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { getVenue } from "features/venue/venueActions";
import { getUnAuthenticatedRouteHeaders } from 'shared/apiHelper';
import { getTheme } from "shared/themeHelper";
import MainApp from "./MainApp";
import SignIn from "./signin";
import SignUp from "./signup";
import Share from "./share";
import Social from "./social";

const propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
  authUser: PropTypes.object
};

const RestrictedRoute = ({
  component: Component,
  location,
  authUser,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};

RestrictedRoute.propTypes = propTypes;

const mapStateToProps = state => ({
  venue: state.venueReducer.venue,
  token: state.authReducer.token
});

const mapDispatchToProps = dispatch => ({
  getVenue: params => dispatch(getVenue(params))
});

class App extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            theme: getTheme()
        }
    }

  componentDidMount() {
    const { location, token, history, venue } = this.props;
    if (!venue.data) {
        this.props.getVenue({
            headers: getUnAuthenticatedRouteHeaders(),
            params: {
                venue_id: process.env.REACT_APP_STADIA_CODE, 
            },
            onSuccess: this.finalizingTheme
        });
    }
    if (location.pathname === "/") {
      if (token === null) {
        history.push("/signin");
      } else {
        history.push("/app/events");
      }
    }
  }

  finalizingTheme = (response) => {
      this.setState({
          theme: {
              ...this.state.theme,
              ...response.theme.color,
          }
      })
  }

  render() {
    const { match, location, token } = this.props;
    const { theme } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/share" component={Share} />
          <Route exact path="/social" component={Social} />
          <RestrictedRoute
            path={`${match.url}`}
            authUser={token}
            location={location}
            component={MainApp}
          />
        </Switch>
      </ThemeProvider>
    );
  }
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(App));
