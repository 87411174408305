import styled from 'styled-components';
import { Select as Sel } from 'antd';

export const Select = styled(Sel)`
    width: ${props => props.width ? props.width : '100%'} !important;
    .ant-select-selection {
        background: ${props => props.theme.select ? props.theme.select.background : ''} !important;
        color: ${props => props.theme.select ? props.theme.select.color : ''} !important;
        border-color: ${props => props.theme.select ? props.theme.select.border : ''} !important;
    }

    .ant-select-focused {
        border-color: ${props => props.theme.select ? props.theme.select.border : ''} !important;
    }

    .ant-select-arrow {
        color: ${props => props.theme.select ? props.theme.select.color : ''} !important;
    }
`;

export const Option = styled(Sel.Option)`
    background: ${props => props.theme.select ? props.theme.select.background : ''} !important;
`;
