import styled from "styled-components";
import { Radio as Rad } from "antd";

export const Radio = styled(Rad)``;
export const RadioButton = styled(Rad.Button)`
  &:before {
    background: transparent !important;
    background-color: transparent !important;
  }
  background: ${props => props.theme.radio ? props.theme.radio.background : ''} !important;
  padding: 11px !important;
  width: 75px !important;
  height: 75px !important;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 100% !important;
`;

export const RadioGroup = styled(Rad.Group)`
  display: flex !important;
  width: 100% !important;
  justify-content: space-between !important;
`;
