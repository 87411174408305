import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import map from "lodash/map";

import { Select, Option } from "shared/components/Select";

const ThreeItemWrapper = styled.div`
  width: 30%;
`;
const StyledFormItem = styled.div`
  display: flex;
  padding-bottom: 20px !important;
  width: 100%;
  justify-content: space-between;
`;

const propTypes = {
  seatMap: PropTypes.object.isRequired,
  updateSelectedSeat: PropTypes.func.isRequired
};

const SeatSelect = ({ seatMap, updateSelectedSeat }) => {
  const [stand, setStand] = useState("");
  const [standDetails, setStandDetails] = useState(null);
  const [block, setBlock] = useState("");
  const [blockDetails, setBlockDetails] = useState(null);
  const [row, setRow] = useState("");
  const [rowDetails, setRowDetails] = useState(null);
  const [seat, setSeat] = useState("");

  const updateSeat = (value) => {
    updateSelectedSeat({
      ...value,
      id: `${value.stand}_${value.block}_${value.row}_${value.seat}` ,
      complete: value.stand && value.block && value.row && value.seat
    });
  }

  const renderStandSelect = () => {
    const options = map(Object.keys(seatMap.map), (stand, index) => {
      return (
        <Option key={index} value={stand}>
          {stand}
        </Option>
      );
    });

    return (
      <Select placeholder="Stand" onChange={handleStandChange}>
        {options}
      </Select>
    );
  };

  const handleStandChange = value => {
    setStandDetails(seatMap.map[value]);
    setStand(value);
    updateSeat({
      stand: value,
      block,
      row,
      seat
    });
  };

  const renderBlockSelect = () => {
    const options =
      standDetails &&
      map(Object.keys(standDetails), (block, index) => {
        return (
          <Option key={index} value={block}>
            {block}
          </Option>
        );
      });

    return (
      <Select
        placeholder="Block"
        disabled={!standDetails}
        onChange={handleBlockChange}
      >
        {options}
      </Select>
    );
  };

  const handleBlockChange = value => {
    setBlockDetails(seatMap.map[stand][value]);
    setBlock(value);
    updateSeat({
      stand,
      block: value,
      row,
      seat
    });
  };

  const renderRowSelect = () => {
    const options =
      blockDetails &&
      map(Object.keys(blockDetails), (row, index) => {
        return (
          <Option key={index} value={row}>
            {row}
          </Option>
        );
      });

    return (
      <Select
        placeholder="Row"
        disabled={!blockDetails}
        onChange={handleRowChange}
      >
        {options}
      </Select>
    );
  };

  const handleRowChange = value => {
    setRowDetails(seatMap.map[stand][block][value]);
    setRow(value);
    updateSeat({
      stand,
      block,
      row: value,
      seat
    });
  };

  const renderSeatSelect = () => {
    const options =
      rowDetails &&
      map(rowDetails, (seat, index) => {
        return (
          <Option key={index} value={seat}>
            {seat}
          </Option>
        );
      });

    return (
      <Select
        placeholder="Seat"
        disabled={!rowDetails}
        onChange={handleSeatChange}
      >
        {options}
      </Select>
    );
  };

  const handleSeatChange = value => {
    setSeat(value);
    updateSeat({
      stand,
      block,
      row,
      seat: value
    });
  };

  return (
    <React.Fragment>
      <StyledFormItem>{renderStandSelect()}</StyledFormItem>
      <StyledFormItem>
        <ThreeItemWrapper>{renderBlockSelect()}</ThreeItemWrapper>
        <ThreeItemWrapper>{renderRowSelect()}</ThreeItemWrapper>
        <ThreeItemWrapper>{renderSeatSelect()}</ThreeItemWrapper>
      </StyledFormItem>
    </React.Fragment>
  );
};

SeatSelect.propTypes = propTypes;

export default SeatSelect;
