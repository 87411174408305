import { all } from "redux-saga/effects";
import eventsSagas from 'features/events/eventsSagas';
import assetsSagas from 'features/assets/assetsSagas';
import authSagas from 'features/auth/authSagas';
import inviteSagas from 'features/invite/inviteSagas';
import subscriptionSagas from 'features/subscription/subscriptionSagas';
import optoutSagas from 'features/optout/optoutSagas';
import reportSagas from 'features/report/reportSagas';
import plansSagas from 'features/plans/plansSagas';
import venueSagas from 'features/venue/venueSagas';

export default function* rootSaga() {
    yield all([
        eventsSagas(),
        assetsSagas(),
        authSagas(),
        inviteSagas(),
        subscriptionSagas(),
        optoutSagas(),
        reportSagas(),
        plansSagas(),
        venueSagas(),
    ]);
}
