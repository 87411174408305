export const getHeaders = (token) => ({
    'access_token': token.access_token,
    'refresh_token': token.refresh_token,
    'version': process.env.REACT_APP_API_VERSION,
    'x-api-key': process.env.REACT_APP_API_KEY
});

export const getUnAuthenticatedRouteHeaders = () => ({
    'version': process.env.REACT_APP_API_VERSION,
    'x-api-key': process.env.REACT_APP_API_KEY
});