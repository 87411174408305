import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import filter from "lodash/filter";
import map from "lodash/map";
import moment from "moment";
import { Tabs, TabPane } from "shared/components/Tabs";
import EventItem from "features/events/EventItem";

const propTypes = {
    events: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

const PageContainer = styled.div`
  padding: 32px 32px 0;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin: 0 -16px;
`;

const Col = styled.div`
  width: 25%;
  box-sizing: border-box;
  padding: 0 16px !important;

  @media (max-width: 1200px) {
    width: 33.33%;
  }

  @media (max-width: 768px) {
    width: 50%;
  }

  @media (max-width: 575px) {
    width: 100%;
  }
`;

const EventsPage = ({ getEvents, events, history }) => {
    const filterCurrentEvents = () => {
        return filter(events.data, event => {
            return moment()
                .startOf("day")
                .isSameOrBefore(moment(event.event_day, "YYYY-MM-DD"));
        });
    };

    const filterPastEvents = () => {
        return filter(events.data, event => {
            return moment()
                .startOf("day")
                .isAfter(moment(event.event_day, "YYYY-MM-DD"));
        });
    };

    const mapEventsToEventItem = events => {
        return map(events, (event, index) => {
            return (
                <Col key={index}>
                    <EventItem key={index} event={event} history={history} refreshEvents={getEvents} />
                </Col>
            );
        });
    };

    return (
        <PageContainer>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Current" key="1">
                    <Row>{mapEventsToEventItem(filterCurrentEvents())}</Row>
                </TabPane>
                <TabPane tab="Past" key="2">
                    <Row>{mapEventsToEventItem(filterPastEvents())}</Row>
                </TabPane>
            </Tabs>
        </PageContainer>
    );
};

EventsPage.propTypes = propTypes;

export default EventsPage;
