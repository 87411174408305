import { Button } from "antd";
import styled from "styled-components";

const StyledButton = styled(Button)`
    background-color: ${props => props.theme.button[props.type] ? props.theme.button[props.type].background : ''} !important;
    border-color: ${props => props.theme.button[props.type] ? props.theme.button[props.type].border : ''} !important;
    color: ${props => props.theme.button[props.type] ? props.theme.button[props.type].color : ''} !important;
    margin: ${props => props.margin ? props.margin : '0'} !important;
    border: ${props => props.border ? props.border: ''} !important;

    &:hover {
        background-color: ${props => props.theme.button[props.type] ? props.theme.button[props.type].hoverBackground : ''} !important;
        color: ${props => props.theme.button[props.type] ? props.theme.button[props.type].hoverColor : ''} !important;
        border-color: ${props => props.theme.button[props.type] ? props.theme.button[props.type].hoverBorder : ''} !important;
    }
`;

export default StyledButton;
