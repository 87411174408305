import React from "react";
import PropTypes from "prop-types";
import { notification } from "antd";
import { Link } from "react-router-dom";

import logo from "shared/assets/images/logo.png";

import CardForm from "features/card/CardForm";
import Button from "shared/components/Button";
import Checkbox from "shared/components/Checkbox";
import { Input, PasswordInput } from "shared/components/Input";
import {
  PageContainer,
  Title,
  Logo,
  StyledForm,
  StyledFormItem,
  SpanText,
  ActionButtonsContainer,
  ActionLink
} from "../commonStyles";

const propTypes = {
  signup: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

const SignUp = ({ signup, history }) => {
  const onSuccess = () => {
    notification.success({
      message:
        "Please verify your email address by clicking on the verification link sent to your email address"
    });
    history.push("/signin");
  };

  const onError = error => {
    notification.error({
      message: error
    });
  };

  const handleSubmit = values => {
    signup({
      data: {
        username: values.email,
        password: values.password,
        attributes: {
          email: values.email
        }
      },
      onSuccess,
      onError
    });
  };

  const validatePassword = (rule, value, callback) => {
    if (value && !value.match("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$")) {
      callback(
        "Password should be of 8 characters and have atleast one uppercase letter and one lowercase letter and one digit"
      );
    }
    callback();
  };

  const leftContent = () => {
    return (
      <React.Fragment>
        <Title>Sign Up</Title>
        <Logo src={logo} />
      </React.Fragment>
    );
  };

  const rightContent = () => {
    return (
      <StyledForm onFinish={handleSubmit}>
        <StyledFormItem
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!"
            },
            {
              required: true,
              message: "Please input your E-mail!"
            }
          ]}
        >
          <Input placeholder="Email" />
        </StyledFormItem>
        <StyledFormItem
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!"
            },
            {
              validator: validatePassword
            }
          ]}
        >
          <PasswordInput placeholder="Password" />
        </StyledFormItem>
        <StyledFormItem
          name="confirm"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "Please confirm your password!"
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "The two passwords that you entered do not match!"
                );
              }
            })
          ]}
        >
          <PasswordInput placeholder="Confirm Password" />
        </StyledFormItem>
        <StyledFormItem
          name="checkbox1"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject("Please accept Privacy Policy")
            }
          ]}
        >
          <Checkbox>
            <span>
              You agree with the{" "}
              <a href="http://snaptivityapp.com/privacy.html">
                <ActionLink>Privacy Policy</ActionLink>
              </a>{" "}
              including collecting, storing and using your personal data for the
              service delivery purposes.
            </span>
          </Checkbox>
        </StyledFormItem>
        <StyledFormItem
          name="checkbox2"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject("Please accept aggrement")
            }
          ]}
        >
          <Checkbox>
            <span>
              You agree to share your personal data with Ajax, Johan Cruijff
              Arena and their sponsors
            </span>
          </Checkbox>
        </StyledFormItem>
        <ActionButtonsContainer>
          <Button
            margin="0 10px 0 0"
            id="sign_up_button"
            type="primary"
            htmlType="submit"
          >
            Sign Up
          </Button>
          <SpanText>or</SpanText>
          <Link to="/signin">
            <ActionLink>Sign In</ActionLink>
          </Link>
        </ActionButtonsContainer>
      </StyledForm>
    );
  };

  return (
    <PageContainer>
      <CardForm
        sideContent={leftContent()}
        sideImage={require("shared/assets/images/stadium.jpg")}
        formContent={rightContent()}
      />
    </PageContainer>
  );
};

SignUp.propTypes = propTypes;

export default SignUp;
